import React from "react";
import { Button, Grid, TextField, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import SignupRegion from "./SignupRegion";
import GoogleIcon from "../../components/svg/GoogleIcon";
import PhonePrefix from "../../components/inputs/PhonePrefix";
import { PhoneValidation } from "../../utils/phoneValidation";
import { KBZ } from "../../config/inappclients";
import { IMG_CDN_URL } from "../../config/constants";

const SigninEmailPassIcon = `${IMG_CDN_URL}/signin-email-pass.svg`;
const SigninOtpIcon = `${IMG_CDN_URL}/signin-otp.svg`;
const kbzpaylogo = `${IMG_CDN_URL}/payment_logo/kbzpay_logo.webp`;
const BoxCloseIcon = `${IMG_CDN_URL}/box-close-icon.svg`;

const SignUpOptionsBox = ({
  handleContinue,
  addId,
  setIsShow,
  setIsSignUp,
  isSocialLogin,
  socialLoginType,
  createPeople,
  setIsDim,
  createdResPeople,
  page,
  handleLogin,
  errorMsg,
  setErrorMsg,
  isDim,
  regValues,
  signUpValues,
  isSpinner,
  setIsSpinner,
  signInWithGoogle,
  setChooseState,
  setIsPhone,
  setIsPhoneOnly,
  setOtpErrMsg,
  setIsForget,
  data,
  setInfoOpen,
  originalPage,
  signInWithPhoneNumber,
  isSignUp,
  isShowSignup,
  setIsShowSignup,
  setSignupOTPUser,
  setIsSignupPhoneVerified,
  isPhone,
  sendOtp,
  setPhoneCountryCode,
  phoneCountryCode,
  phoneCountryFlag,
  setPhoneCountryFlag,
  otpErrMsg,
  phonenumber,
  setPhonenumber,
  isPhonePass,
  otpPassword,
  setOtpPassword,
  isDisable,
  clickSendOtp,
  setIsPhonePass,
  otp,
  setotp,
  OTPValidation,
  signUpOTPLoading,
  setSignUpOTPLoading,
  setSendOtp,
  clientTag,
  signupOTPUser,
  isInApp,
  campaignId,
  utmId,
  setSocialLoginType,
  setIsEmailOtp,
  setIsEmailPass,
  isShowOTP,
  setIsShowOTP,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div id="signup-container">
      {(isShowSignup || isSignUp) && !isShowOTP && (
        <SignupRegion
          handleContinue={handleContinue}
          addId={addId}
          setIsShow={setIsShow}
          setIsSignUp={setIsSignUp}
          isSocialLogin={isSocialLogin}
          socialLoginType={socialLoginType}
          createPeople={createPeople}
          setIsDim={setIsDim}
          createdResPeople={createdResPeople}
          page={page}
          handleLogin={handleLogin}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          isDim={isDim}
          regValues={regValues}
          signUpValues={signUpValues}
          isSpinner={isSpinner}
          setIsSpinner={setIsSpinner}
          setIsForget={setIsForget}
          data={data}
          setInfoOpen={setInfoOpen}
          originalPage={originalPage}
          setSignupOTPUser={setSignupOTPUser}
          campaignId={campaignId}
          utmId={utmId}
        />
      )}

      {isShowOTP && isPhone && !sendOtp && (
        <div className="login-left-inner signin">
          <div className="signup-title-div">
            <h3>Create Account</h3>
          </div>
          <span className="signup-second-title">
            Sign up and discover great amount of new opportunities.
          </span>
          <div className="infobox">
            <div style={{ display: "flex" }}>
              <Grid className="phone-container" style={{ marginLeft: "3px" }}>
                <PhonePrefix
                  handlePhonePrefix={setPhoneCountryCode}
                  prefixValue={phoneCountryCode}
                  handlePhoneFlag={setPhoneCountryFlag}
                  mobileCountryFlag={phoneCountryFlag}
                  showSpanLabel={false}
                  dropdownStyle={{
                    background: "white",
                    border: "none",
                  }}
                />
              </Grid>
              <TextField
                className={
                  otpErrMsg !== ""
                    ? "error-textbox login-box-phone"
                    : "login-box-phone"
                }
                name="Phone Number"
                type="text"
                value={phonenumber}
                autoComplete="off"
                onChange={(e) => {
                  setOtpErrMsg("");
                  setPhonenumber(e.target.value);
                }}
                onBlur={() => {
                  const num = /^[0-9]+$/;
                  const firstNo = phonenumber.charAt(0);
                  if (!num.test(phonenumber) || Number(firstNo) === 0) {
                    setOtpErrMsg("Please check your phone number.");
                    return;
                  }
                  if (
                    phoneCountryCode === "95" &&
                    !PhoneValidation(phonenumber)
                  ) {
                    setOtpErrMsg("Please enter valid number.");
                    return;
                  }
                  setOtpErrMsg("");
                }}
                // label="Phone Number"
                placeholder="Phone Number"
                required
                error={otpErrMsg !== "" ? true : false}
                variant="standard"
              />
            </div>
          </div>
          {isPhonePass && (
            <TextField
              className={otpErrMsg !== "" ? "error-textbox" : ""}
              name="otpPassword"
              type="password"
              value={otpPassword}
              autoComplete="off"
              onChange={(e) => {
                setOtpErrMsg("");
                setOtpPassword(e.target.value);
              }}
              label={
                t("masterPassengerInformationTwo.password") !==
                "masterPassengerInformationTwo.password"
                  ? t("masterPassengerInformationTwo.password")
                  : "Password"
              }
              required
              error={otpErrMsg !== "" ? true : false}
            />
          )}
          {otpErrMsg !== "" && <p>{otpErrMsg}</p>}
          <span className="phoneInfo">
            e.g. 9123456789, for country code (+95) and mobile No.9123456789
          </span>
          <br />
          <br />
          <div id="recaptcha-container"></div>
          <Button
            color="primary"
            variant="contained"
            className={`btn-send-otp ${isDisable ? "dim-btn-send-otp" : ""}`}
            onClick={signInWithPhoneNumber}
            disabled={clickSendOtp}
          >
            {t(`auth.send_otp`) !== "auth.send_otp"
              ? t(`auth.send_otp`)
              : "Send OTP"}{" "}
            {clickSendOtp && <CircularProgress />}
          </Button>
          {isDisable && (
            <p>
              {" "}
              {t(`auth.wait_next_attempt`) !== "auth.wait_next_attempt"
                ? t(`auth.wait_next_attempt`)
                : "Please wait 60secs before the next attempt."}
            </p>
          )}
        </div>
      )}
      {isShowOTP && sendOtp && (
        <div className="login-left-inner signin">
          <div className="signup-title-div">
            <h3>Create Account</h3>
            <img
              src={BoxCloseIcon}
              alt=""
              onClick={() => {
                setIsShow(false);
                setIsForget(false);
                setIsSignUp(false);
                setSignupOTPUser(null);
              }}
            />
          </div>
          <span className="signup-second-title">
            Sign up and discover great amount of new opportunities..
          </span>
          <div className="infobox signup-otp">
            <label>OTP*</label>
            <input
              className={errorMsg !== "" ? "error-textbox" : ""}
              name="OTP"
              type="text"
              value={otp}
              autoComplete="off"
              onChange={(e) => {
                setotp(e.target.value);
              }}
              label="OTP"
              required
              error={errorMsg !== "" ? true : false}
              variant="standard"
            />
            <Button
              color="primary"
              variant="contained"
              className="btn-send-otp"
              onClick={() => {
                setSignUpOTPLoading(true);
                OTPValidation("signup");
              }}
            >
              {t(`auth.verify`) !== "auth.verify" ? t(`auth.verify`) : "Verify"}{" "}
              {signUpOTPLoading && <CircularProgress />}
            </Button>
          </div>
        </div>
      )}

      {page !== "profile" &&
        page !== "addressBook" &&
        clientTag !== KBZ &&
        !window.location.search.includes("miniapp") && (
          <>
            <Grid
              item={true}
              sm={12}
              md={12}
              xs={12}
              className="member-account"
            >
              <div className="ask-signin-div">
                <span
                  className="signIn_btn"
                  id="login_btn"
                  onClick={() => {
                    setIsShowSignup(false);
                    setSendOtp(false);
                    setSignupOTPUser(null);
                    setIsSignupPhoneVerified(false);
                    setIsSignUp(false);
                    setIsPhone(true);
                    setIsPhoneOnly(true);
                    setIsEmailOtp(false);
                    setIsEmailPass(false);
                  }}
                >
                  {t("masterPassengerInformationTwo.already_have_account") !==
                  "masterPassengerInformationTwo.already_have_account"
                    ? t("masterPassengerInformationTwo.already_have_account")
                    : "Already have an account?"}{" "}
                  Login
                </span>
              </div>
              <div className="signup-divider">
                <span className="border-line">
                  <hr />
                </span>
                <span className="border-title">or create an account with</span>
                <span className="border-line">
                  <hr />
                </span>
              </div>
            </Grid>

            {isSignUp && (
              <div className="infobox infobox-loginbuttons signUpOptBox">
                {clientTag === KBZ &&
                  signupOTPUser?.login_types?.filter((val) => val.id === "6")
                    .length === 0 && (
                    <Button
                      size="small"
                      color="success"
                      className="btn-login-box"
                      onClick={() => {
                        setIsSignUp(true);
                        setIsSignupPhoneVerified(false);
                        setIsShowSignup(true);
                      }}
                      startIcon={
                        <img
                          src={kbzpaylogo}
                          alt=""
                          style={{ width: 18, height: 18 }}
                        />
                      }
                    >
                      Signup via kbzpay
                    </Button>
                  )}
                {!isInApp && (
                  <Button
                    size="small"
                    color="success"
                    className="btn-login-box"
                    onClick={() => {
                      //setIsSignUp(false);
                      setIsShowSignup(true);
                      // setIsSignupPhoneVerified(false);
                      signInWithGoogle("signup");
                    }}
                    startIcon={<GoogleIcon />}
                  >
                    Google
                  </Button>
                )}
                {!isShowOTP && (
                  <Button
                    size="small"
                    color="success"
                    className="btn-login-box"
                    onClick={() => {
                      setIsPhone(true);
                      // setIsPhoneOnly(true);
                      signInWithPhoneNumber();
                      //setIsSignupPhoneVerified(false);
                      setIsSignUp(true);
                      setIsShowSignup(true);
                      setIsShowOTP(true);
                    }}
                    startIcon={<img src={SigninOtpIcon} alt="" />}
                  >
                    Phone number (SMS)
                  </Button>
                )}
                {isShowOTP && (
                  <Button
                    size="small"
                    color="success"
                    className="btn-login-box"
                    onClick={() => {
                      setIsSignUp(true);
                      setIsShowSignup(true);
                      setIsShowOTP(false);
                      setIsSignupPhoneVerified(false);
                      setSocialLoginType("");
                    }}
                    startIcon={<img src={SigninEmailPassIcon} alt="" />}
                  >
                    Email
                  </Button>
                )}
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default SignUpOptionsBox;
