import { useState } from "react";
import { CREATE_WALLET_ACTIVITY } from "../queries/customerinfoquery";
import { useMutation } from "@apollo/react-hooks";
import { LOGIN_VIA_KBZPAY } from "../config/constants";
import {
  setKbzLoginToLocalStorage,
  storeKbzUserProfileInformation,
} from "../utils/loginutils";

function KBZPayConsentHook(sessionName) {
  const [kbzCusInfo, setKbzCusInfo] = useState(null);
  const [kbzWalletPhone, setKbzWalletPhone] = useState(null);
  const [kbzPopup, setKbzPopup] = useState(false);
  const [isKbzSignLoading, setIsKbzSingLoading] = useState(false);

  const [saveWalletActivity] = useMutation(CREATE_WALLET_ACTIVITY, {
    onCompleted: (res) => {
      const resData = res.createWalletActivityLog;
      if (
        resData &&
        resData.status &&
        resData.access_token &&
        resData.user &&
        resData.user.arranger
      ) {
        if (sessionName === "loginViaKbzpay") {
          setKbzLoginToLocalStorage(resData);
          storeKbzUserProfileInformation(resData);
          window.location.reload();
        } else {
          setKbzPopup(
            sessionStorage.getItem(sessionName)
              ? sessionStorage.getItem(sessionName)
              : true,
          );
        }
        setKbzCusInfo(resData);
        sessionStorage.setItem(LOGIN_VIA_KBZPAY, JSON.stringify(resData));
      }
      //alert(JSON.stringify(resData));
      setKbzWalletPhone(resData);
      setIsKbzSingLoading(false);
      //alert(localStorage.getItem("miniappinfo"));
    },
    onError: (err) => {
      console.log(err);
      alert(err);
      setIsKbzSingLoading(false);
    },
  });

  return {
    saveWalletActivity,
    kbzPopup,
    setKbzPopup,
    kbzCusInfo,
    kbzWalletPhone,
    isKbzSignLoading,
    setIsKbzSingLoading,
  };
}

export default KBZPayConsentHook;
