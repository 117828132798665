import React from "react";
import { Grid, Modal } from "@material-ui/core";
import { CALL_CENTER_PHONENO, IMG_CDN_URL } from "../../config/constants";
import { useTranslation } from "react-i18next";

const closeIcon = `${IMG_CDN_URL}/relief_flight_close_icon.png`;
const SuccessImg = `${IMG_CDN_URL}/cc_girl.svg`;
const ErrorImg = `${IMG_CDN_URL}/car_error.svg`;

const HotelLongAlertModal = ({
  confirmModal,
  setConfirmModal,
  bookingConfirm,
  bookingError,
  classes,
  classPhone,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Modal open={confirmModal} id="reliefModal" className={classes}>
        <Grid className="insurance-confirmation-modal">
          <Grid
            item={true}
            sm={12}
            style={{
              display: "inline-block",
              width: "100%",
            }}
          >
            {bookingConfirm && (
              <h2>
                {t("travelServices.inquiry_received") !==
                "travelServices.inquiry_received"
                  ? t("travelServices.inquiry_received")
                  : "Inquiry Received!"}
              </h2>
            )}
            {bookingError && (
              <h2>
                {" "}
                {t("travelServices.inquiry_error") !==
                "travelServices.inquiry_error"
                  ? t("travelServices.inquiry_error")
                  : "Something went wrong! Please try again."}
              </h2>
            )}
            <img
              src={closeIcon}
              alt="close"
              onClick={() => {
                setConfirmModal(false);
                window.location.reload();
              }}
              style={{
                width: "15px",
                height: "15px",
                position: "absolute",
                top: "15px",
                right: "15px",
                zIndex: "1",
              }}
              className="closeImg"
            />
          </Grid>
          {bookingConfirm && (
            <div className="img-region">
              <img src={SuccessImg} alt="" />
            </div>
          )}
          {bookingConfirm && (
            <p>
              {t("travelServices.inquiry_message1") !==
              "travelServices.inquiry_message1"
                ? t("travelServices.inquiry_message1")
                : "We have received your inquiry and would like to thank you for writing to us."}
            </p>
          )}
          {bookingError && (
            <div className="img-region">
              <img src={ErrorImg} alt="" />
            </div>
          )}
          {bookingError && (
            <p>
              {t("travelServices.inquiry_error_message") !==
              "travelServices.inquiry_error_message"
                ? t("travelServices.inquiry_error_message")
                : "Your reservation is not completed. Please try again."}
            </p>
          )}
          <p>
            {t("travelServices.inquiry_message2") !==
            "travelServices.inquiry_message2"
              ? t("travelServices.inquiry_message2")
              : "If your inquiry is urgent, call our 24-hour hotline number"}
            <br />
            <span className={classPhone}>{CALL_CENTER_PHONENO}</span>
            {t("travelServices.inquiry_message3") !==
            "travelServices.inquiry_message3"
              ? t("travelServices.inquiry_message3")
              : "to reach customer support rep."}
          </p>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

export default HotelLongAlertModal;
