import React from "react";
import { Modal, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const LoyaltyProgramMenu = ({
  showModal,
  setShowModal,
  isInApp,
  isInWeb,
  selectedLanguage,
}) => {
  const navigate = useNavigate();
  return (
    <div
      open={showModal}
      id="loyaltyMenuModal"
      className={`loyaltyMenuModal ${showModal ? "open" : ""}`}
    >
      <div className="overlay" onClick={() => setShowModal(false)}></div>
      <div className="loyalty-program-menu">
        <ArrowDropUpIcon />
        <p>Do you want to explore Loyalty Program? </p>
        <span>speacial offer for you!</span>
        <div className="button-wrap">
          <Button
            className="delete-no"
            style={{
              backgroundColor: "#ddd",
              color: "#686868",
            }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate({
                pathname: isInApp
                  ? `/${selectedLanguage}/in-app/loyalty-program`
                  : isInWeb
                  ? `/${selectedLanguage}/in-web/loyalty-program`
                  : `/${selectedLanguage}/loyalty-program`,
                search: window.location.search,
              });
              setShowModal(false);
            }}
          >
            Explore
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyProgramMenu;
