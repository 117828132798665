import React, { useRef, useEffect, useState, useCallback } from "react";
import PageHeader from "../../pages/PageHeader";
import { checkInApp, checkInWeb, getLocale } from "../../utils/checkurl";
import {
  MM_LOCALE,
  EN_LOCALE,
  MY_MM,
  LOGIN_PEOPLE_DETAIL,
  SOURCE_TYPE_REFERRAL,
  SOURCE_TYPE_DEVICE,
  SOURCE_TYPE_CAMPAIGN,
  CC_PHONENO_LINK,
} from "../../config/constants";
import { Button, Card, Grid, Modal } from "@material-ui/core";
import {
  LocationOnRounded,
  CreditCardRounded,
  CheckRounded,
} from "@material-ui/icons";
import "../../assets/scss/HotelLongStayResultDetail.scss";
import {
  BOOK_LONG_STAY_HOTEL,
  HOTEL_DETAIL_BY_ID,
  LONG_STAY_HOTEL_ROOM_PRICES,
} from "../../queries/hotelquery";
import HotelSearchBox from "../hotels/HotelSearchBox";
import { Navigate } from "react-router-dom";
import { withRouter } from "../../utils/withRouter";
import { clientV3 } from "../../utils/v3utils";
import LoadingComponent from "../../components/LoadingResultComponent";
import HotelImageGallery from "./HotelImageGallery";
import HotelFooter from "../../components/hotel/HotelFooter";
import HotelDesc from "../hotels/HotelDesc";
import HotelLongBlock from "./HotelLongBlock";
import MediaQuery from "react-responsive";
import StarIcon from "@material-ui/icons/Star";
import HotelMobilePricePanel from "../../components/hotel/mobile/HotelMobilePricePanel";
import { findIndex } from "lodash";
// import { calculateNights } from "../../utils/date";
import HotelMobileMaps from "../../components/hotel/mobile/HotelMobileMaps";
import HotelBackButton from "../../components/hotel/mobile/HotelBackButton";
import { updateGorgiasIcon } from "../../utils/updateGorgiasIcon";
import { getParam } from "../../utils/hotelUtil";
import { useParams } from "react-router-dom";
import { getClientTag } from "../../utils/checkurl";
import {
  KBZSC_IN_WEB,
  VIBER_IN_WEB,
  CITY_REWARD_IN_WEB,
} from "../../config/inwebclients";
import HotelLongStayResult from "./HotelLongStayResult";
import HotelInquiryFormWeb from "./HotelInquiryFormWeb";
import HotelPassengerFormMobile from "./HotelPassengerFormMobile";
import { GetSalesChannelID } from "../../utils/common";
import { getSourceData } from "../../utils/getSourceData";
import HotelLongAlertModal from "./HotelLongAlertModal";
import { InsuranceHomeStyles } from "../insurance/styles/InsuranceHomeStyles";
import OfflineInternationalMsg from "../../components/modal/OfflineInternationalMsg";
import CloseIcon from "@material-ui/icons/Close";
import {
  SEND_MAIL_RESERVATION_CROSS_CC,
  SEND_RESERVATION_SMS,
} from "../../queries/paymentquery";
import { useMutation } from "@apollo/react-hooks";
import useFlymyaSessionHook from "../../hooks/FlymyaSessionHook";
import FlymyaIconLoading from "../../components/FlymyaIconLoading";
import HomeMenu from "../HomeMenu";

const HotelLongStayResultDetail = (props) => {
  let params = useParams();
  const [loading, setLoading] = useState(false);
  const [language] = useState(
    getLocale(params.locale) === MY_MM ? MM_LOCALE : EN_LOCALE,
  );
  const [selectedLanguage] = useState(getLocale(params.locale));
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const [propertyName] = useState(decodeURIComponent(params.city));
  const [checkIn, setCheckIn] = useState(params.checkIn);
  const [checkOut, setCheckOut] = useState(params.checkOut);
  const [adultChildRoom, setAdultChildRoom] = useState(params.adultChildRoom);
  const [hotelId] = useState(params.hotelId);
  const [latitude] = useState(params.lat);
  const [longitude] = useState(params.long);
  const [hotel, setHotel] = useState({});
  const [currency] = useState(params.currency);
  //   const [stars] = useState(
  //     Array(Number(params.stars))
  //       .fill()
  //       .map((star, i) => {
  //         return <StarIcon key={`star${i}`} className="h-icon yellow-icon" />;
  //       }),
  //   );
  const [nationality, setNationality] = useState(params.nationality);
  const [roomData, setRoomData] = useState([]);
  const [roomCart, setRoomCart] = useState([]);
  const [roomCartRooms, setRoomCartRooms] = useState(0);
  const [roomCartTotal, setRoomCartTotal] = useState(0);
  const [phoneNo, setPhoneNo] = useState("");
  const [openPricePanel, setOpenPricePanel] = useState(false);
  const [isCustomPrice, setIsCustomPrice] = useState(true);
  const [isRedirect, setIsRedirect] = useState(false);
  //const [showModal, setShowModal] = useState(false);
  const [fullRoomFlag] = useState(false);

  // const showPrice = getParam(window.location.search, "show_price");
  const [clientTag] = useState(getClientTag(window.location.search));
  const [guestNo, setGuestNo] = useState("1");
  const [duration_of_stay, setDOS] = useState("1 Month");
  const [total, setTotal] = useState("");
  const [hotelRoomPrices, setHotelRoomPrices] = useState([]);
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [bookbychat, setBookByChat] = useState(false);
  const showPrice = params?.showPrice;
  const [confirmModal, setConfirmModal] = useState(false);
  const [bookingError, setBookingError] = useState(false);
  const [bookingConfirm, setBookingConfirm] = useState(false);
  const [noRoomSelected, setNoRoomSelected] = useState(false);
  const [loadingInquiry, setLoadingInquiry] = useState(false);
  const classes = InsuranceHomeStyles();
  const [myanmarFont] = useState(
    getLocale(params.locale) === MY_MM ? "myanmarFont" : "",
  );
  const [showMenu, setShowMenu] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("flight");

  const { handleFlymyaSession } = useFlymyaSessionHook();

  useEffect(() => {
    handleFlymyaSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchHotel = useCallback(() => {
    if (clientV3 && params.hotelId) {
      setLoading(true);
      clientV3
        .mutate({
          mutation: HOTEL_DETAIL_BY_ID,
          variables: {
            input: {
              hotel_ids: params.hotelId,
            },
          },
        })
        .then((res) => {
          setHotel(res.data.hotelDetails[0].hotel_data);
          setRoomData(res.data.hotelDetails[0].room_data);
          setPhoneNo(res.data.hotelDetails[0].phoneno);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setHotel({});
          setLoading(false);
        });
    }
  }, [params.hotelId]);

  const fetchHotelPrice = useCallback(() => {
    if (clientV3 && params.hotelId) {
      setLoading(true);
      clientV3
        .mutate({
          mutation: LONG_STAY_HOTEL_ROOM_PRICES,
          variables: {
            hotelId: Number(params.hotelId),
          },
        })
        .then((res) => {
          setHotelRoomPrices(res.data.longStayHotelRoomPrices);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [params.hotelId]);

  const mounted = useRef();

  const formikRef = useRef();

  useEffect(() => {
    if (!mounted.current) {
      updateGorgiasIcon();
      fetchHotel();
      mounted.current = true;
    }
  }, [language]);

  useEffect(() => {
    fetchHotelPrice();
    setCart([]);
    setTotalPrice(0);
  }, []);

  const addRemoveFromCart = (
    block_id,
    quantity,
    cost,
    allPrices,
    taxes,
    max_occupancy,
    action,
  ) => {
    let cart = roomCart;
    let total = 0;
    let totalRooms = 0;
    let existingIndex = findIndex(cart, function (o) {
      return o["block_id"] === block_id;
    });
    if (action === "add") {
      if (existingIndex > -1) {
        cart[existingIndex].quantity = quantity;
        cart[existingIndex].cost = cost;
        cart[existingIndex].allPrices = allPrices;
        cart[existingIndex].maxOccupancy = max_occupancy;
      } else {
        cart.push({
          block_id: block_id,
          quantity: quantity,
          cost: cost,
          taxes: taxes,
          allPrices: allPrices,
          maxOccupancy: max_occupancy,
        });
      }
    } else if (action === "remove") {
      if (existingIndex > -1) {
        if (quantity === 0) {
          cart.splice(existingIndex, 1);
        } else {
          cart[existingIndex].quantity = quantity;
          cart[existingIndex].cost = cost;
          cart[existingIndex].allPrices = allPrices;
          cart[existingIndex].maxOccupancy = max_occupancy;
        }
      }
    }
    cart.forEach((c) => {
      total += c.cost;
      totalRooms += c.quantity;
    });
    setRoomCart(cart);
    setRoomCartTotal(total);
    setRoomCartRooms(totalRooms);
  };

  const handleValueChange = (name, value) => {
    //name === "showModal" && setShowModal(value);
    name === "isCustomPrice" && setIsCustomPrice(value);
    name === "checkIn" && setCheckIn(value);
    name === "checkOut" && setCheckOut(value);
    name === "adultChildRoom" && setAdultChildRoom(value);
    //name === "fullRoomFlag" && setFullRoomFlag(value);
  };

  const getFlightBgClass = () => {
    const inweb = window.location.pathname.includes("in-web");

    if (inweb && clientTag === KBZSC_IN_WEB) {
      return "inWeb bgKbz";
    }

    if (inweb && clientTag === VIBER_IN_WEB) {
      return "inWeb bgViber";
    }

    if (inweb && clientTag === CITY_REWARD_IN_WEB) {
      return "inWeb bgCityReward";
    }
  };

  let strData = localStorage.getItem(LOGIN_PEOPLE_DETAIL)
    ? JSON.parse(localStorage.getItem(LOGIN_PEOPLE_DETAIL))
    : null;

  const [sendReservationSms] = useMutation(SEND_RESERVATION_SMS, {
    onCompleted: (res) => {},
    onError: (err) => {},
  });

  const sendMailCC = (reservationID) => {
    if (reservationID === null) return;
    // if (isAuthenticated() && (isB2B || isCallCenter)) {
    clientV3.mutate({
      mutation: SEND_MAIL_RESERVATION_CROSS_CC,
      variables: {
        id: reservationID,
        template: "reservation_multi_products",
        attachment_template: "",
      },
    });
    // }
  };

  const handleCreateReservation = (values, resetForm, web) => {
    let bookingInformation = {
      affiliate_id: 1924621,
      hotel_id: Number(params.hotelId),
      block_ids: cart?.map((r) => r.room_id + "_000000000_1_1_0").join(","),
      block_quantities: Array.from({ length: cart?.length }, () => "1").join(
        ",",
      ),
      booker_country: "mm",
      booker_title: values?.title,
      booker_email: values?.email,
      booker_firstname: values?.name,
      booker_lastname: values?.last_name,
      booker_telephone: `${values?.phonePrefix}${values?.phone}`,
      cc_cardholder: "",
      cc_cvc: "",
      cc_expiration_date: "",
      cc_number: "",
      cc_type: 0,
      duration: web
        ? Number(duration_of_stay?.split(" ")[0])
        : Number(values?.duration_of_stay?.split(" ")[0]),
      // duration: Number(duration_of_stay?.split(" ")[0]),
      date_type: "month",
      incremental_prices: prices,
      guest_names: Array.from(
        { length: cart?.length },
        () => `${values?.name} ${values?.last_name}`,
      ).join(","),
      // guest_quantities: roomCart?.map(r => r.quantity).join(','),
      guest_quantities: web
        ? Array.from({ length: cart?.length }, () => guestNo).join(",")
        : Array.from({ length: cart?.length }, () => values?.guest_no).join(
            ",",
          ),
      currency: "USD",
      guest_emails: `${values?.email}`,
      ga_client_id: "1826718602.1676363155",
      is_travelling_for_work: false,
      is_main_guest: false,
      sales_channel_id: GetSalesChannelID(
        values,
        strData,
        isInApp,
        isInWeb,
        clientTag,
        "longstay",
      ),
      // contact_type_id:
      //   values.isCheckEmail && values.isCheckSMS
      //     ? 3
      //     : values.isCheckEmail && !values.isCheckSMS
      //     ? 1
      //     : 2,
      // duration_of_stay: duration_of_stay,
      duration_of_stay: web ? duration_of_stay : values?.duration_of_stay,
      sources: getSourceData(),
      arranger: {
        id: "",
        prefix: values?.title,
        first_name: values?.name,
        last_name: values?.last_name,
        phone_prefix: `${values.phonePrefix}`,
        phone: `${values?.phone}`,
        email: values?.email,
        contact_type_id:
          values.isCheckEmail && values.isCheckSMS
            ? 3
            : values.isCheckEmail && !values.isCheckSMS
            ? 1
            : 2,
      },
      mode: "nb_price",
      sparam: "foreigner/2023-07-12/2023-07-12/2023-07-13/2-0-2/2053696",
    };
    setLoadingInquiry(true);
    clientV3
      .mutate({
        mutation: BOOK_LONG_STAY_HOTEL,
        variables: {
          input: bookingInformation,
        },
      })
      .then((result) => {
        setLoadingInquiry(false);
        if (values && values.isCheckEmail && values.isCheckSMS) {
          // sendMailCC(result?.data?.longStayBooking?.reservation_id);
          sendReservationSms({
            variables: {
              input: {
                reservation_id: result?.data?.longStayBooking?.reservation_id,
              },
            },
          });
        } else if (values && !values.isCheckEmail && values.isCheckSMS) {
          sendReservationSms({
            variables: {
              input: {
                reservation_id: result?.data?.longStayBooking?.reservation_id,
              },
            },
          });
        } else {
          // sendMailCC(result?.data?.longStayBooking?.reservation_id);
        }
        // resetForm();
        setCart([]);
        setTotalPrice(0);
        setShowContactUsModal(false);
        setBookingConfirm(true);
        setBookingError(false);
        setConfirmModal(true);
        // result &&
        //   result.data &&
        //   result.data.hotelProcessBooking &&
        //   setReservationData(result.data.hotelProcessBooking);
        // result &&
        //   result.data &&
        //   result.data.hotelProcessBooking &&
        //   setIsConfirmation(true);
        // result &&
        //   result.data &&
        //   result.data.hotelProcessBooking &&
        //   setIsError(false);
      })
      .catch((error) => {
        console.log(error, "err..");
        setBookingError(true);
        setBookingConfirm(false);
        setConfirmModal(true);
        setLoadingInquiry(false);
        // setIsError(true);
      });
  };

  const prices = cart
    .map((room) => (room?.price && showPrice ? room?.price : "0"))
    .join(",");

  return (
    <div className={`${getFlightBgClass()} HotelDetailPage long-stay`}>
      <HotelLongAlertModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        bookingConfirm={bookingConfirm}
        bookingError={bookingError}
        classes={classes.insuranceAlertModal}
        classPhone={classes.insurancePhoneNumber}
      />
      <HotelBackButton history={props.history} city={"Back"} />

      {/* <PageHeader
        pageName="HotelDetailPage"
        selectedLanguage={selectedLanguage}
        pageType=""
        pathName={props.location}
        isInApp={isInApp}
        isInWeb={isInWeb}
        gorgiasInfo={{
          roomName: null,
          currency: currency,
          checkIn: checkIn,
          checkOut: checkOut,
          minPriceToShow: null,
          hotelName: hotel ? hotel.name : null,
          phoneNumber: null,
        }}
      /> */}
      <HomeMenu
        props={props}
        isInApp={isInApp}
        isInWeb={window.location.pathname.includes("in-web") ? true : false}
        myanmarFont={myanmarFont}
        selectedLanguage={selectedLanguage}
        clientTag={getClientTag(window.location.search)}
        toggleDrawer={() => setShowMenu(!showMenu)}
        showMenu={showMenu}
        pageName="AboutUs"
        // gorgiasInfo={gorgiasInfo}
        setSelectedProduct={setSelectedProduct}
        selectedProduct={selectedProduct}
      />
      {loadingInquiry && <FlymyaIconLoading />}
      {/* Desktop */}
      <MediaQuery minWidth={781}>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: "25px", marginBottom: "25px" }}
        >
          <Grid
            container
            item
            xs={12}
            sm={12}
            spacing={3}
            style={{ justifyContent: "center" }}
          >
            <Grid container item md={12} lg={9} className="hotel-main">
              {loading ? (
                <>
                  <LoadingComponent />
                </>
              ) : hotel && hotel.name ? (
                <>
                  <h2 className="search-res-title">
                    <span className="hotel-badge">Hotel</span>
                    {"  " + hotel.name}
                  </h2>
                  <div className="d-flex align-items-center">
                    <LocationOnRounded className="h-icon blue-icon" />
                    <span className="hotel-address">{hotel.address}</span>
                  </div>

                  {hotel?.hotel_photos?.length > 0 && (
                    <HotelImageGallery imgs={hotel.hotel_photos} />
                  )}

                  {hotel.creditcard_required && !isCustomPrice && (
                    <div className="cc-info d-flex align-items-center">
                      <CreditCardRounded className="fg-green" />
                      <span>
                        <strong>No credit card needed to book.</strong> We'll
                        send you an email confirming your reservation.
                      </span>
                    </div>
                  )}

                  <HotelInquiryFormWeb
                    handleReservation={handleCreateReservation}
                    guestNo={guestNo}
                    setGuestNo={setGuestNo}
                    duration_of_stay={duration_of_stay}
                    setDOS={setDOS}
                    total={total}
                    setTotal={setTotal}
                    formikRef={formikRef}
                  ></HotelInquiryFormWeb>

                  <HotelLongBlock
                    isCustomPrice={isCustomPrice}
                    hotel={hotel}
                    roomData={roomData}
                    setCart={setCart}
                    cart={cart}
                    totalPrice={totalPrice}
                    setTotalPrice={setTotalPrice}
                    hotelRoomPrices={hotelRoomPrices}
                    handleValueChange={handleValueChange}
                    isInApp={isInApp}
                    isInWeb={isInWeb}
                    handleCreateReservation={handleCreateReservation}
                    formikRef={formikRef}
                    //showModal={showModal}
                    fullRoomFlag={fullRoomFlag}
                    showPrice={showPrice}
                    phoneNo={phoneNo}
                    noRoomSelected={noRoomSelected}
                    setNoRoomSelected={setNoRoomSelected}
                    duration_of_stay={duration_of_stay}
                  />

                  <HotelDesc hotel={hotel} />
                </>
              ) : (
                <h3 className="search-res-title">Hotel NOT FOUND</h3>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MediaQuery>
      {/* Tablet or Mobile */}
      <MediaQuery maxWidth={780}>
        <Grid container justifyContent="center">
          <Grid item xs={12} className="hotel-main">
            {loading ? (
              <LoadingComponent />
            ) : hotel && hotel.name ? (
              <>
                <Grid item xs={12}>
                  <div
                    style={{
                      backgroundImage: `url(${
                        hotel &&
                        hotel.hotel_photos &&
                        hotel?.hotel_photos?.length > 0 &&
                        hotel.hotel_photos[0].url_original
                      })`,
                      backgroundSize: "cover",
                      height: "200px",
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="mobile-hotel-detail">
                  <Card className="mobile-hotel-detail-card">
                    <Grid item xs={12} className="d-flex align-items-center">
                      <h2 className="search-res-title">{hotel.name}</h2>
                      {/* <span className="stars">{stars}</span> */}
                    </Grid>
                    <Grid item xs={12}>
                      {hotel?.hotel_photos?.length > 0 && (
                        <HotelImageGallery
                          imgs={hotel.hotel_photos}
                          mobile={true}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {hotel.creditcard_required && !isCustomPrice && (
                        <div className="cc-info d-flex align-items-center">
                          <CreditCardRounded className="fg-green" />
                          <span>
                            <strong>No credit card needed to book.</strong>{" "}
                            We'll send you an email confirming your reservation.
                          </span>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <div className="d-flex align-items-center">
                        <LocationOnRounded className="h-icon blue-icon" />
                        <span className="hotel-address">{hotel.address}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <HotelLongBlock
                        hotel={hotel}
                        roomData={roomData}
                        setCart={setCart}
                        cart={cart}
                        totalPrice={totalPrice}
                        setTotalPrice={setTotalPrice}
                        hotelRoomPrices={hotelRoomPrices}
                        addRemoveFromCart={addRemoveFromCart}
                        handleValueChange={handleValueChange}
                        isCustomPrice={isCustomPrice}
                        isInApp={isInApp}
                        isInWeb={isInWeb}
                        roomCartRooms={roomCartRooms}
                        //showModal={showModal}
                        fullRoomFlag={fullRoomFlag}
                        showPrice={showPrice}
                        noRoomSelected={noRoomSelected}
                        setNoRoomSelected={setNoRoomSelected}
                        duration_of_stay={duration_of_stay}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <HotelMobileMaps
                        hotel={hotel.name}
                        city={propertyName || hotel.city}
                        lat={latitude || hotel?.location?.latitude}
                        long={longitude || hotel?.location?.longitude}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <HotelDesc hotel={hotel} />
                    </Grid>
                  </Card>
                  {cart?.length > 0 && (
                    <Grid item xs={12} className="hotel-detail-footer">
                      {/* <div
                        className="price-tab"
                        onClick={() => setOpenPricePanel(true)}
                      >
                        Pricing Detail
                      </div> */}
                      <div
                        className="d-flex justify-content-between price-tab-body align-items-center"
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <span>You can select more than 1 room type!</span>
                        {/* <Grid item xs={6}>
                          <div className="currency">
                            <span>{`$`}</span>
                            <span>
                              {totalPrice.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                          </div>
                          <div>
                            <span className="d-flex">
                              <CheckRounded className="ava-icon h-icon grey-icon" />
                              <span>{"Includes taxes & fees"}</span>
                            </span>
                          </div>
                        </Grid> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            style={{
                              width: "30%",
                              padding: 0,
                              lineHeight: 1,
                              minHeight: 35,
                              fontSize: 12,
                            }}
                            variant="contained"
                            className="hotel-btn reserve-btn"
                            onClick={() => setBookByChat(true)}
                          >
                            Book by chat
                          </Button>
                          <Button
                            style={{
                              width: "30%",
                              padding: 0,
                              lineHeight: 1,
                              minHeight: 35,
                              fontSize: 12,
                            }}
                            variant="contained"
                            className="hotel-btn reserve-btn"
                            onClick={() => {
                              window.open(`tel:${CC_PHONENO_LINK}`);
                            }}
                          >
                            Call us! <br />
                            +959 880 441 001
                          </Button>
                          <Button
                            style={{
                              width: "30%",
                              padding: 0,
                              lineHeight: 1,
                              minHeight: 35,
                              fontSize: 12,
                            }}
                            variant="contained"
                            className="hotel-btn reserve-btn"
                            onClick={() => setShowContactUsModal(true)}
                          >
                            Continue inquiry
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <h3 className="search-res-title">Hotel NOT FOUND</h3>
            )}
          </Grid>
        </Grid>
      </MediaQuery>

      {!loading && <HotelFooter />}
      {/* {openPricePanel && (
        <MediaQuery maxWidth={780}>
          <HotelMobilePricePanel
            currency={currency}
            roomCart={roomCart}
            roomCartRooms={roomCartRooms}
            roomCartTotal={roomCartTotal}
            hotel={hotel}
            totalNights={calculateNights(checkOut, checkIn)}
            isOpen={openPricePanel}
            closePricePanel={() => setOpenPricePanel(false)}
            isCustomPrice={isCustomPrice}
          />
        </MediaQuery>
      )} */}
      <HotelPassengerFormMobile
        // sendGorgiaMessage={sendGorgiaMessage}
        // searchInfo={localStorage.getItem("gorgiasInfo")}
        // productDetail={hotel}
        handleReservation={handleCreateReservation}
        showContactUsModal={showContactUsModal}
        setShowContactUsModal={setShowContactUsModal}
      />
      <OfflineInternationalMsg
        clientTag={clientTag}
        // productDetail={hotel}
        showContactUsModal={bookbychat}
        setShowContactUsModal={setBookByChat}
        type="hotel"
      />

      <React.Fragment>
        <Modal
          className="offlineIntMsgModal"
          open={noRoomSelected}
          id="reliefModal"
          onClose={(event, reason) => {
            if (reason === "backdropClick" && reason !== "escapeKeyDown") {
              setNoRoomSelected(false);
            }
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              display: "block",
              position: "absolute",
              top: "30%",
              padding: 30,
              width: 400,
              //touch-action: none;
              borderRadius: 8,
              outline: "none",
              textAlign: "center",
            }}
          >
            {cart?.length === 0 ? (
              <p>Please Select at least one room</p>
            ) : (
              <p>All Guest Information is required.</p>
            )}
            <div
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                outline: "none",
              }}
            >
              <button
                onClick={() => {
                  setNoRoomSelected(false);
                }}
                style={{
                  outline: "none",
                  border: "none",
                  backgroundColor: "#fff",
                }}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    </div>
  );
};

export default withRouter(HotelLongStayResultDetail);
