import { gql } from "apollo-boost";

const LOYALTY_PROGRAM_DATA_BY_ARRANGER = gql`
  query ($id: ID!) {
    arranger(id: $id) {
      id
      loyalty_program {
        id
        sign_up
        logged_in_mobile
        joined_experience_myanmar
        invited_friend
        arranger {
          id
        }
        referral_by {
          id
        }
        booked_flight
        booked_bus
        booked_hotel
        added_friend
        loyalty_tier {
          id
          tier
          label
          amount
          currency
        }
      }
    }
  }
`;

const LOYALTY_PROGRAM_EDIT = gql`
  mutation ($input: loyaltyProgramEditInput) {
    editLoyaltyProgram(input: $input) {
      id
      sign_up
      logged_in_mobile
      joined_experience_myanmar
      invited_friend
      arranger {
        id
      }
      booked_flight
      booked_bus
      booked_hotel
      added_friend
      tier
    }
  }
`;

const LOYALTY_TIERS = gql`
  query loyaltyTiers {
    loyaltyTiers {
      id
      tier
      label
      amount
      currency
      points
      deleted_at
      created_at
      updated_at
      sign_up
      logged_in_mobile
      joined_experience_myanmar
      invited_friends
      booked_flight
      booked_bus
      booked_hotel
      added_friend
    }
  }
`;

const LOYALTY_TIERS_INSIDER = gql`
  query loyaltyTiers {
    loyaltyTiers {
      id
      tier
      label
      amount
      currency
      points
      deleted_at
      created_at
      updated_at
      sign_up
      logged_in_mobile
      joined_experience_myanmar
      invited_friends
      booked_flight
      booked_bus
      booked_hotel
      added_friend
    }
  }
`;

//Loyalty Program V2
const GET_PRODUCT_TYPES = gql`
  {
    productType {
      id
      name
    }
  }
`;

const LOYALTY_PROGRAM_V2 = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    loyaltyProgramV2(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        arranger_id
        tier_id
        points
        tier_expiration_date
        total_points
        total_amount_spend
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

const LOYALTY_TIER_V2 = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    loyaltyTierV2(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        tier
        label
        amount_per_point_mmk
        tier_threshold_amount_mmk
        tier_threshold_period_month
        names {
          id
          content_model_id
          language_id
          translation_field_id
          translation_text
        }
        descriptions {
          id
          content_model_id
          language_id
          translation_field_id
          translation_text
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

const ARRANGER_LOYALTY_POINT = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    arrangerLoyaltyPoint(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        arranger_id
        event_id
        points
        transaction_id
        transaction_type_id
        transaction_home_amount
        status_id
        subject
        reservation_id
        coupon {
          id
          names {
            id
            language_id
            translation_field_id
            translation_text
          }
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

const GET_COUPONS = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    coupon(filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
      data {
        id
        product_type_id
        amount
        currency
        coupon_type
        valid_period_month
        active
        required_points
        total_purchased
        attachments {
          id
          filename
          download_link
        }
        names {
          id
          language_id
          translation_field_id
          translation_text
        }
        descriptions {
          id
          language_id
          translation_field_id
          translation_text
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

const GET_ARRANGER_COUPONS = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    arrangerCoupon(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        coupon {
          id
          coupon_type
          product_type_id
          amount
          currency
          attachments {
            id
            filename
            download_link
          }
          names {
            id
            language_id
            translation_field_id
            translation_text
          }
          descriptions {
            id
            language_id
            translation_field_id
            translation_text
          }
          required_points
        }
        coupon_key
        coupon_id
        arranger_id
        expiry_date
        status
        reservation_id
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

const ACTIVE_ARRANGER_COUPONS = gql`
  query activeArrangerCoupons(
    $input: activeArrangerCouponsInput
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    activeArrangerCoupons(
      input: $input
      page: $page
      first: $first
      orderBy: $orderBy
    ) {
      id
      coupon {
        id
        coupon_type
        product_type_id
        currency
        amount
        required_points
        attachments {
          id
          filename
          download_link
        }
        names {
          id
          language_id
          translation_field_id
          translation_text
        }
        descriptions {
          id
          language_id
          translation_field_id
          translation_text
        }
      }
      coupon_key
      coupon_id
      arranger_id
      expiry_date
      status
      reservation_id
      created_at
      updated_at
      deleted_at
    }
  }
`;

const REMOVE_ARRANGER_COUPON = gql`
  mutation ($input: removeCouponInput) {
    removeCoupon(input: $input) {
      id
      total {
        id
        physical_amount
      }
      sources {
        id
        items {
          id
          key
          value
        }
        source_type_id
        customer_charge {
          id
          physical_amount
          physical_currency
        }
      }
    }
  }
`;

const COUPON_APPLY = gql`
  mutation ($input: couponApplyInput) {
    couponApply(input: $input) {
      id
      total {
        id
        physical_amount
      }
      sources {
        id
        items {
          id
          key
          value
        }
        source_type_id
        promo {
          id
          code
        }
        customer_charge {
          id
          physical_amount
          physical_currency
        }
      }
    }
  }
`;

const BUY_COUPON = gql`
  mutation ($input: buyCouponInput) {
    buyCoupon(input: $input) {
      id
      coupon {
        id
        product_type_id
        amount
        currency
      }
      coupon_key
      coupon_id
      arranger_id
      expiry_date
      status
      reservation_id
      created_at
      updated_at
      deleted_at
    }
  }
`;

const GET_MERCHANDISES = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    merchandise(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        required_points
        show_on_fe
        available
        attachments {
          id
          filename
          download_link
        }
        names {
          id
          content_model_id
          language_id
          translation_field_id
          translation_text
        }
        descriptions {
          id
          content_model_id
          language_id
          translation_field_id
          translation_text
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

const BUY_MERCHANDISE = gql`
  mutation ($input: buyMerchandiseInput) {
    buyMerchandise(input: $input) {
      id
      merchandise_id
      arranger_id
      status
    }
  }
`;

export {
  LOYALTY_PROGRAM_DATA_BY_ARRANGER,
  LOYALTY_PROGRAM_EDIT,
  LOYALTY_TIERS,
  LOYALTY_TIERS_INSIDER,
  LOYALTY_PROGRAM_V2,
  LOYALTY_TIER_V2,
  ARRANGER_LOYALTY_POINT,
  GET_COUPONS,
  GET_PRODUCT_TYPES,
  GET_ARRANGER_COUPONS,
  ACTIVE_ARRANGER_COUPONS,
  REMOVE_ARRANGER_COUPON,
  COUPON_APPLY,
  BUY_COUPON,
  GET_MERCHANDISES,
  BUY_MERCHANDISE,
};
