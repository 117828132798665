import getClientId from "../utils/getClientId";
import moment from "moment";
import { LOGIN_PEOPLE_DETAIL } from "../config/constants";
let clientId = getClientId();

export const PaymentMutation = (paymentParams) => {
  return paymentParams.client.mutate({
    variables: {
      input: {
        gateway: paymentParams.gateway,
        token: paymentParams.token ? paymentParams.token : "",
        description: paymentParams.bookingId ? paymentParams.bookingId : "",
        metadata: {
          reservationId: paymentParams.bookingId
            ? paymentParams.bookingId
            : "-",
          otherId: paymentParams.otherId
            ? paymentParams.otherId
            : paymentParams.paymentMethod,
          invoiceNo: paymentParams.bookingId ? paymentParams.bookingId : "",
          purchaserMsisdn: paymentParams.contactPhone
            ? String(paymentParams.contactPhone)
            : "",
          returnUrl: paymentParams.returnUrl ? paymentParams.returnUrl : "",
          cancelUrl: paymentParams.cancelUrl ? paymentParams.cancelUrl : "",
          timeout: paymentParams.totalSec ? paymentParams.totalSec : 600,
          failUrl: paymentParams.failUrl ? paymentParams.failUrl : "",
        },
        gaClientId: clientId,
      },
    },
    mutation: paymentParams.query,
  });
};

export const PaymentTopupMutation = (paymentParams) => {
  return paymentParams.client.mutate({
    variables: {
      input: {
        gateway: paymentParams.gateway,
        token: paymentParams.token ? paymentParams.token : "",
        description: paymentParams.bookingId ? paymentParams.bookingId : "",
        amount: paymentParams.amount ? paymentParams.amount : "",
        currency: paymentParams.currency ? paymentParams.currency : "",
        metadata: {
          reservationId: paymentParams.bookingId
            ? paymentParams.bookingId
            : "-",
          otherId: paymentParams.otherId ? paymentParams.otherId : "",
          invoiceNo: paymentParams.bookingId ? paymentParams.bookingId : "",
          purchaserMsisdn: paymentParams.contactPhone
            ? paymentParams.contactPhone
            : "",
          returnUrl: paymentParams.returnUrl ? paymentParams.returnUrl : "",
          cancelUrl: paymentParams.cancelUrl ? paymentParams.cancelUrl : "",
          timeout: paymentParams.totalSec ? paymentParams.totalSec : 600,
          failUrl: paymentParams.failUrl ? paymentParams.failUrl : "",
          cardholderEmail: paymentParams.contactEmail
            ? paymentParams.contactEmail
            : "",
        },
        gaClientId: "",
      },
    },
    mutation: paymentParams.query,
  });
};
export const B2BPaymentMutation = (paymentParams) => {
  return paymentParams.client.mutate({
    variables: {
      input: {
        id: paymentParams.bookingId,
        status_id: 1,
        transactions: [
          {
            id: 5274,
            transaction_type_id: 1,
            payer_id: JSON.parse(localStorage.getItem(LOGIN_PEOPLE_DETAIL)).id,
            payment_method_id: paymentParams.paymentMethod,
            external_id: "",
            remarks: "",
            finance_remarks: "",
            payment_date: moment(new Date()).format("YYYY-MM-DD"),
            status_id: 18,
            amount: {
              id: "",
              physical_amount: paymentParams.amount,
              home_exchange_rate: paymentParams.exchangeRate,
              physical_currency: paymentParams.currency,
              home_amount: 0,
              home_currency: "USD",
              show_fare_amount: null,
              show_fare_exchange_rate: null,
            },
            net_base: {
              id: "",
              physical_currency: paymentParams.currency,
              physical_amount: 0,
              home_exchange_rate: paymentParams.exchangeRate,
              home_amount: 0,
              home_currency: null,
              show_fare_amount: null,
              show_fare_exchange_rate: null,
            },
            customer_charge: {
              id: "",
              physical_currency: paymentParams.currency,
              home_exchange_rate: 1,
              physical_amount: 0,
              home_amount: 0,
              home_currency: null,
              show_fare_amount: null,
              show_fare_exchange_rate: null,
            },
            attachments: [],
          },
        ],
      },
    },
    mutation: paymentParams.query,
  });
};

export const PaymentCheckingStatus = (
  client,
  checkQuery,
  bookingId,
  gateway,
  uuid,
) => {
  return client.query({
    query: checkQuery,
    fetchPolicy: "network-only",
    variables: {
      input: {
        gateway: gateway,
        metadata: {
          uuid: uuid,
          reservationId: bookingId,
        },
      },
    },
  });
};

export const sendFeedBackReport = (client, checkQuery, input) => {
  return client.mutate({
    mutation: checkQuery,
    variables: {
      input: input,
    },
  });
};
