import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Popover } from "@material-ui/core";
import {
  LOGIN_USER_ID,
  IDENTIFIER,
  LOGIN_USER_PEOPLE,
  IS_CUSTOMER,
} from "../config/constants";
import {
  MPITESAN,
  CITIZENPAY,
  MOMONEY,
  AYAPAY,
  KBZ,
  WAVEMONEY,
  UABPAY,
} from "../config/inappclients";
import { isAuthenticated } from "../lib/auth";
import { clientV3 } from "../utils/v3utils";
import { LOGOUT_QUERY } from "../queries/loginquery";
import { checkInApp, checkInWeb, getClientTag } from "../utils/checkurl";
import OTP_LOGIN_EMAIL_REGEX from "../config/regex/otpLoginEmailRegex";
import { removeFromLocalStorage } from "../utils/logoututil";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IMG_CDN_URL } from "../config/constants";

const LoginIconWhite = `${IMG_CDN_URL}/header-menu/login_icon_white.svg`;
const LoginIconBlack = `${IMG_CDN_URL}/header-menu/login_icon_black.svg`;
const LoginIconGreen = `${IMG_CDN_URL}/header-menu/login_green.svg`;
const LoginIconGreenInapp = `${IMG_CDN_URL}/header-menu/login_green_inapp.svg`;

const AgentLoginMenu = ({
  selectedLanguage,
  isShow,
  setIsShow,
  setIsCreateAcc,
  isMenuShow,
  setIsMenuShow,
  isMobile,
  isWhiteColor,
  isInApp,
  userData,
  showModal,
  pageName,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [clientTag] = useState(getClientTag(window.location.search));
  const [displayUserIdentity, setDisplayUserIdentity] = useState("");
  const { t } = useTranslation();

  const isCustomer =
    localStorage.getItem(IS_CUSTOMER) &&
    localStorage.getItem(IS_CUSTOMER) === "yes"
      ? true
      : false;

  const navigate = useNavigate();
  const location = useLocation();

  const getDisplayLoginUserIdentity = () => {
    const identifier = localStorage.getItem(IDENTIFIER);
    if (OTP_LOGIN_EMAIL_REGEX.test(identifier)) {
      setDisplayUserIdentity(identifier.split("@")[0]);
      return identifier.split("@")[0];
    }
    return identifier;
  };

  /** useEffect to load display name to state from localstorage when component is first mounted */
  useEffect(() => {
    setDisplayUserIdentity(getDisplayLoginUserIdentity());
  }, []);

  const commonFunc = () => {
    removeFromLocalStorage();
    if (checkInApp(location.pathname)) {
      window.location.href = `/${selectedLanguage}/in-app${window.location.search}`;
    } else if (checkInWeb(location.pathname)) {
      window.location.href = `/${selectedLanguage}/in-web${window.location.search}`;
    } else {
      window.location.href = `/${selectedLanguage}${window.location.search}`;
    }
  };

  const getAccountLogo = (isMobile, isInApp, isWhiteColor, clientTag) => {
    if (!isMobile) return LoginIconBlack;
    if (!isInApp) return LoginIconGreen;
    if (
      isWhiteColor &&
      (clientTag === MPITESAN ||
        clientTag === CITIZENPAY ||
        clientTag === MOMONEY ||
        clientTag === AYAPAY)
    )
      return LoginIconGreen;

    if (
      isInApp &&
      clientTag === KBZ &&
      pageName !== "Flight" &&
      pageName !== "Hotel" &&
      pageName !== "Bus" &&
      pageName !== "Car" &&
      pageName !== "Balloon" &&
      pageName !== "Promotions" &&
      pageName !== "Insurance" &&
      pageName !== "verifyTicket"
    ) {
      return LoginIconGreen;
    }

    return LoginIconGreenInapp;
  };

  const handleLogout = () => {
    clientV3
      .mutate({
        mutation: LOGOUT_QUERY,
      })
      .then(() => {
        commonFunc();
      })
      .catch((err) => {
        console.log(err);
        let msg =
          err &&
          err.graphQLErrors &&
          err.graphQLErrors.length > 0 &&
          err.graphQLErrors[0]
            ? err.graphQLErrors[0].extensions.reason
            : "";
        if (msg === "Not Authenticated") {
          commonFunc();
        }
      });
  };

  return (
    <>
      {!isAuthenticated() && (
        <span
          className="login_btn"
          id="login_btn"
          onClick={() => {
            setIsShow(!isShow);
            setIsCreateAcc && setIsCreateAcc(false);
          }}
          style={{ marginLeft: 0 }}
        >
          {isMobile && (
            <img
              src={LoginIconBlack}
              alt=""
              className={isMobile ? "mobile_login_icon" : "desktop_login_icon"}
            />
          )}
          {/* {!isMobile && <span className="header_login_box">Login</span>} */}
          {!isMobile && (
            <p
              className="header-createaccount"
              style={{ margin: 0, fontSize: 14 }}
            >
              Login
            </p>
          )}
        </span>
      )}
      {isAuthenticated() && (
        <>
          {!isMobile && (
            <span
              aria-describedby="logout"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              style={{ marginLeft: 0 }}
            >
              <img
                src={LoginIconGreen}
                alt=""
                className={"desktop_loggedin_icon"}
              />
            </span>
          )}
          {isMobile && (
            <span
              className={`login_btn ${!isInApp ? "not_inapp_login" : ""}`}
              id="login_btn"
              aria-describedby="logout"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <img
                src={getAccountLogo(isMobile, isInApp, isWhiteColor, clientTag)}
                alt=""
                className={`${isMobile ? "mobile_login_icon" : ""} ${
                  !isInApp ? "not_inapp_icon" : ""
                }`}
                style={{ width: 25 }}
              />
            </span>
          )}
          <Popover
            id="logout"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <p className="emailAddressLoginPopoverText">
              <span className="signedTitle">
                {t(`b2c.signed_in_as`) !== "b2c.signed_in_as"
                  ? t(`b2c.signed_in_as`)
                  : "Signed in as"}
              </span>
              <span className="signedName">
                {displayUserIdentity?.length > 21
                  ? `${displayUserIdentity.substring(0, 21)}...`
                  : displayUserIdentity}
              </span>
            </p>
            <hr className="hr_menu"></hr>
            {!isCustomer && (
              <span
                onClick={() =>
                  navigate({
                    pathname: `/${selectedLanguage}/${
                      checkInApp(location.pathname)
                        ? "in-app/"
                        : checkInWeb(location.pathname)
                        ? "in-web/"
                        : ""
                    }balance/${localStorage.getItem(LOGIN_USER_ID)}`,
                    search: window.location.search,
                  })
                }
              >
                {t(`balance.balance`) !== "balance.balance"
                  ? t(`balance.balance`)
                  : "Balance"}
              </span>
            )}
            {isCustomer && (
              <>
                <span
                  onClick={() =>
                    navigate({
                      pathname: `/${selectedLanguage}/${
                        checkInApp(location.pathname)
                          ? "in-app/"
                          : checkInWeb(location.pathname)
                          ? "in-web/"
                          : ""
                      }profile/${localStorage.getItem(LOGIN_USER_ID)}`,
                      search: window.location.search,
                      userData,
                    })
                  }
                  style={{ position: "relative" }}
                >
                  {t(`b2c.view_profile`) !== "b2c.view_profile"
                    ? t(`b2c.view_profile`)
                    : "View Profile"}{" "}
                  {/* <span
                    style={{
                      fontSize: "10px",
                      color: theme.palette.info.contrastText,
                      fontWeight: "bold",
                      letterSpacing: "0.5px",
                      background: theme.palette.info.main,
                      padding: "2px 5px",
                      borderRadius: "10px",
                      width: "25px",
                      position: "absolute",
                      top: "-5px",
                      marginLeft: "7px",
                    }}
                  >
                    New!
                  </span> */}
                </span>
                <span
                  onClick={() =>
                    navigate({
                      pathname: `/${selectedLanguage}/${
                        checkInApp(location.pathname)
                          ? "in-app/"
                          : checkInWeb(location.pathname)
                          ? "in-web/"
                          : ""
                      }address-book/${localStorage.getItem(LOGIN_USER_ID)}`,
                      search: window.location.search,
                    })
                  }
                  style={{ position: "relative" }}
                >
                  {t(`b2c.address_book`) !== "b2c.address_book"
                    ? t(`b2c.address_book`)
                    : "Address Book"}{" "}
                  {/* <span
                    style={{
                      fontSize: "10px",
                      color: theme.palette.info.contrastText,
                      fontWeight: "bold",
                      letterSpacing: "0.5px",
                      background: theme.palette.info.main,
                      padding: "2px 5px",
                      borderRadius: "10px",
                      width: "25px",
                      position: "absolute",
                      top: "-5px",
                      marginLeft: "7px",
                    }}
                  >
                    New!
                  </span> */}
                </span>
              </>
            )}
            <span
              onClick={() =>
                navigate({
                  pathname: `/${selectedLanguage}/${
                    checkInApp(location.pathname)
                      ? "in-app/"
                      : checkInWeb(location.pathname)
                      ? "in-web/"
                      : ""
                  }booking-history/${localStorage.getItem(LOGIN_USER_ID)}`,
                  search: window.location.search,
                })
              }
            >
              {t(`b2c.booking_history`) !== "b2c.booking_history"
                ? t(`b2c.booking_history`)
                : "Booking History"}
            </span>
            {/* {isCustomer && (
              <span
                onClick={() =>
                  navigate({
                    pathname: `/${selectedLanguage}/${
                      checkInApp(location.pathname)
                        ? "in-app/"
                        : checkInWeb(location.pathname)
                        ? "in-web/"
                        : ""
                    }insider-dashboard/${localStorage.getItem(
                      LOGIN_USER_PEOPLE,
                    )}`,
                    search: window.location.search,
                  })
                }
              >
                {t(`b2c.insider_dashboard`) !== "b2c.insider_dashboard"
                  ? t(`b2c.insider_dashboard`)
                  : "Insider Dashboard"}
              </span>
            )} */}
            <hr className="hr_menu"></hr>
            <span onClick={handleLogout}>
              {t(`b2c.log_out`) !== "b2c.log_out" ? t(`b2c.log_out`) : "Logout"}
            </span>
          </Popover>
        </>
      )}
    </>
  );
};

export default AgentLoginMenu;
