import moment from "moment";

export const DayOption = (dayOption) => {
  return Array.apply(0, Array(dayOption)).map(function (x, i) {
    const val = (i + 1).toString().length === 1 ? "0" + (i + 1) : i + 1;
    return {
      value: val,
      label: val,
    };
  });
};

export const MonthOption = () => {
  return [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
};

export const YearOption = (type, passengerType) => {
  let startYear = "";
  let endYear = "";
  let thisYear = moment().format("Y");
  if (type === "exp") {
    startYear = thisYear;
    endYear = Number(startYear) + 20;
  } else {
    startYear = Number(thisYear);
    if (passengerType === "Infant") {
      endYear = Number(thisYear) - 2;
    } else {
      endYear = Number(thisYear) - 100;
    }
  }

  let result = [];
  if (type === "exp") {
    for (let i = startYear; i <= endYear; i++) {
      result.push({ value: String(i), label: String(i) });
    }
  } else {
    for (let i = startYear; i >= endYear; i--) {
      result.push({ value: String(i), label: String(i) });
    }
  }

  return result;
};
