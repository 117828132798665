/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  MenuList,
  MenuItem,
  Drawer,
  Button,
  Snackbar,
  Slide,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MenuIcon from "@material-ui/icons/Sort";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { HandleMenu, HandleFlag } from "../utils/inapptoggle";
import { retry } from "../utils/retryloadable";
//import { triggerEmail } from "../utils/gorgiasTicket";
import TestCheckoutModal from "../components/modal/TestCheckoutModal";
import { removeFromLocalStorage } from "../utils/logoututil";
import {
  IS_CUSTOMER,
  LOGIN_PEOPLE_COMPANY,
  IS_B2B,
  MY_MM,
  LOGIN_PEOPLE_DETAIL,
  SOCIAL_TOKEN,
  //CC_PHONENO_LINK,
  IDENTIFIER,
  LOGIN_USER_ID,
  LOGIN_EMAIL,
  LOGIN_PHONE,
  LOGIN_NAME,
  TRAVEL_VISA_SERVICE,
  TRAVEL_VIP_SERVICE,
} from "../config/constants";
import { clientV3 } from "../utils/v3utils";
import {
  FIREBASE_LOGIN_QUERY,
  RESEND_VERIFY_EMAIL,
  AGENT_LOGIN_QUERY,
} from "../queries/loginquery";
import { useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { isAuthenticated } from "../lib/auth";
import {
  gtmB2BLogin,
  gtmB2CLogin,
  gtmB2BClassType,
} from "../utils/gtmDataLayer";
import { setToLocalStorage, storeUserProfileInfo } from "../utils/loginutils";
import {
  KBZSC_IN_WEB,
  VIBER_IN_WEB,
  CITY_REWARD_IN_WEB,
} from "../config/inwebclients";
import {
  MPITESAN,
  CITIZENPAY,
  MOMONEY,
  AYAPAY,
  ONEPAY,
  KBZ,
} from "../config/inappclients";
import { isMobile } from "react-device-detect";
import { useTheme } from "@mui/material/styles";
import { CHECK_AUTH, FORCE_LOGOUT } from "../queries/loginuserquery";
import OfflineInternationalMsg from "../components/modal/OfflineInternationalMsg";
import TestMenuItem from "../components/menuitems/TestMenuItem";
import FeedbackModal from "../components/modal/FeedbackModal";
import FeedbackConfirmModal from "../components/modal/FeedbackConfirmModal";
import { BalloonHomeStyles } from "./balloon/styles/BalloonHomeStyles";
import { useTranslation } from "react-i18next";
import useComponentHasPermission from "../hooks/componentHasPermissionHook";
import "./HomeMenuDropDown.scss";
import { isInAppUrl, isInWebUrl } from "../utils/checkurl";
import useFlymyaSessionHook from "../hooks/FlymyaSessionHook";
import LoyaltyProgramLogo from "../assets/images/loyalty-tier/Tier-logo.png";
import LoyaltyProgramMenu from "../components/loyaltyProgram/LoyaltyProgramMenu";
import { EmployeeLogin } from "../utils/common";
import { IMG_CDN_URL } from "../config/constants";

const feedback = `${IMG_CDN_URL}/feedback.png`;
// import GorgiasInAppIcon from "../components/GorgiasIcon";
const PhoneMailIcon = `${IMG_CDN_URL}/Phone&Mail_black_new.svg`;
const ETCIcon = `${IMG_CDN_URL}/etc.jpg`;
const PhoneMailIconWhite = `${IMG_CDN_URL}/Phone&Mail_White_new.svg`;
const NewLogoWhite = `${IMG_CDN_URL}/header-menu/flymya_mobile_logo_white2.svg`;
const NewLogo = `${IMG_CDN_URL}/header-menu/flymya_mobile_logo.svg`;
const KBZPayLogo = `${IMG_CDN_URL}/kbzpay-logo.svg`;
const VerityTicketDesktop = `${IMG_CDN_URL}/verify_ticket_desktop.svg`;
const VerityTicketLogo = `${IMG_CDN_URL}/verify_ticket.svg`;
const VerityTicketLogoWhite = `${IMG_CDN_URL}/verify_ticket_white.svg`;

const HomeMenuWeb = lazy(() => retry(() => import("./HomeMenuWeb")));
const ReactFlags = lazy(() =>
  retry(() => import("../components/inputs/ReactFlags")),
);
const FlightMenuItem = lazy(() =>
  retry(() => import("../components/menuitems/FlightMenuItem")),
);
const HotelLongMenuItem = lazy(() =>
  retry(() => import("../components/menuitems/HotelLongMenuItem")),
);
// const BusMenuItem = lazy(() =>
//   retry(() => import("../components/menuitems/BusMenuItem")),
// );
const TravelServicesMenuItem = lazy(() =>
  retry(() => import("../components/menuitems/TravelServicesMenuItem")),
);
// const InsuranceMenuItem = lazy(() =>
//   retry(() => import("../components/menuitems/InsuranceMenuItem")),
// );
// const TourPackagesMenuItem = lazy(() =>
//   retry(() => import("../components/menuitems/TourPackagesMenuItem")),
// );
const BalloonMenuItem = lazy(() =>
  retry(() => import("../components/menuitems/BalloonMenuItem")),
);
const CarMenuItem = lazy(() =>
  retry(() => import("../components/menuitems/CarMenuItem")),
);
// const BlogMenuItem = lazy(() =>
//   retry(() => import("../components/menuitems/BlogMenuItem")),
// );
const PromotionMenuItem = lazy(() =>
  retry(() => import("../components/menuitems/PromotionMenuItem")),
);
const LoginSignupModal = lazy(() =>
  retry(() => import("./login/LoginSignupModal")),
);
const AgentLoginMenu = lazy(() => retry(() => import("./AgentLoginMenu")));

const MerchandiseMenuItem = lazy(() =>
  retry(() => import("../components/menuitems/MerchandiseMenuItem.js")),
);

const HomeMenu = ({
  props,
  isInApp,
  isInWeb,
  myanmarFont,
  selectedLanguage,
  clientTag,
  toggleDrawer,
  showMenu,
  pageName,
  //gorgiasInfo,
  setSelectedProduct,
  selectedProduct,
  hideHeader,
  hotelType,
  setHotelType,
  // permissionArr
  type,
}) => {
  let params = useParams();
  const [isShow, setIsShow] = useState(false);
  const [isCreateAcc, setIsCreateAcc] = useState(false);
  const [isForget, setIsForget] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDim, setIsDim] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [loginSuccessOpen, setLoginSuccessOpen] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const { t } = useTranslation();
  const [
    showB2BAccountBlockContactDetail,
    setShowB2BAccountBlockContactDetail,
  ] = useState(false);
  const mounted = useRef();
  const navigate = useNavigate();

  const [isCheck, setIsCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [showLoyaltyMenu, setShowLoyaltyMenu] = useState(false);

  const isProductHomePage = [
    "Flight",
    "Car",
    "TravelServices",
    "Insurance",
    "Promotions",
    "Balloon",
  ].includes(pageName)
    ? true
    : false;

  const { asynGetPermission } = useComponentHasPermission({
    isInApp,
  });

  const {
    handleFlymyaSession,
    handleCreateUserAction,
    handleUpdateArrangerId,
  } = useFlymyaSessionHook();

  const currentUrlParams = new URLSearchParams(window.location.search);
  if (currentUrlParams.has("hotelId") || currentUrlParams.has("cp")) {
    currentUrlParams.delete("hotelId");
    currentUrlParams.delete("cp");
  }

  let urlFlightPath = isInWeb
    ? isInWebUrl(isInWeb, selectedLanguage, "flight/")
    : isInAppUrl(isInApp, selectedLanguage, "flight/");
  let urlBusPath = isInWeb
    ? isInWebUrl(isInWeb, selectedLanguage, "bus/")
    : isInAppUrl(isInApp, selectedLanguage, "bus/");
  let urlHotelPath = isInWeb
    ? isInWebUrl(isInWeb, selectedLanguage, "hotel/")
    : isInAppUrl(isInApp, selectedLanguage, "hotel/");
  let urlLongPath = isInWeb
    ? isInWebUrl(isInWeb, selectedLanguage, "hotel-long-stay")
    : isInAppUrl(isInApp, selectedLanguage, "hotel-long-stay");
  let urlTravelServicePath = isInWeb
    ? isInWebUrl(isInWeb, selectedLanguage, "travel-services")
    : isInAppUrl(isInApp, selectedLanguage, "travel-services");
  let urlInsurancePath = isInWeb
    ? isInWebUrl(isInWeb, selectedLanguage, "insurance")
    : isInAppUrl(isInApp, selectedLanguage, "insurance");
  let urlTourPath = isInWeb
    ? isInWebUrl(isInWeb, selectedLanguage, "tour-packages")
    : isInAppUrl(isInApp, selectedLanguage, "tour-packages");
  let urlCarPath = isInWeb
    ? isInWebUrl(isInWeb, selectedLanguage, "car-rental")
    : isInAppUrl(isInApp, selectedLanguage, "car-rental");
  let urlBalloonPath = isInWeb
    ? isInWebUrl(isInWeb, selectedLanguage, "balloon")
    : isInAppUrl(isInApp, selectedLanguage, "balloon");
  let urlPromotionPath = isInWeb
    ? isInWebUrl(isInWeb, selectedLanguage, "promotion")
    : isInAppUrl(isInApp, selectedLanguage, "promotion");

  useEffect(() => {
    if (pageName !== "Rewards") {
      if (
        window.location.pathname === "/" ||
        window.location.pathname === "/en" ||
        window.location.pathname === "/en/" ||
        window.location.pathname === "/my-mm" ||
        window.location.pathname === "/my-mm/" ||
        window.location.pathname === "/zh" ||
        window.location.pathname === "/zh/" ||
        window.location.pathname === "/in-app" ||
        window.location.pathname === "/in-app/" ||
        window.location.pathname === "/en/in-app" ||
        window.location.pathname === "/en/in-app/" ||
        window.location.pathname === "/my-mm/in-app" ||
        window.location.pathname === "/my-mm/in-app/" ||
        window.location.pathname === "/zh/in-app" ||
        window.location.pathname === "/zh/in-app/" ||
        window.location.pathname === "/in-web" ||
        window.location.pathname === "/in-web/" ||
        window.location.pathname === "/en/in-web" ||
        window.location.pathname === "/en/in-web/" ||
        window.location.pathname === "/my-mm/in-web" ||
        window.location.pathname === "/my-mm/in-web/" ||
        window.location.pathname === "/zh/in-web" ||
        window.location.pathname === "/zh/in-web/"
      ) {
        setSelectedProduct("flight");
      } else {
        isInWeb || isInApp
          ? setSelectedProduct(window.location.pathname.split("/")[3])
          : setSelectedProduct(window.location.pathname.split("/")[2]);
      }
    }
  }, [isInWeb, selectedProduct]);

  useEffect(() => {
    const getLoginDetailFromLS = localStorage.getItem(LOGIN_PEOPLE_DETAIL);
    if (
      isAuthenticated() &&
      localStorage.getItem(IS_CUSTOMER) &&
      localStorage.getItem(IS_CUSTOMER) === "yes" &&
      localStorage.getItem(SOCIAL_TOKEN) &&
      localStorage.getItem(SOCIAL_TOKEN) !== "" &&
      (getLoginDetailFromLS === "false" ||
        getLoginDetailFromLS === "" ||
        getLoginDetailFromLS === "undefined")
    ) {
      setIsShow(true);
    }

    isAuthenticated() && checkAuth();
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      asynGetPermission();
      if (
        localStorage.getItem("isLoginVerify") &&
        localStorage.getItem("isLoginVerify") === "yes"
      ) {
        setLoginSuccessOpen(true);
        localStorage.removeItem("isLoginVerify");
      }
      if (
        localStorage.getItem("isSignupVerify") &&
        localStorage.getItem("isSignupVerify") === "yes"
      ) {
        setInfoOpen(true);
        localStorage.removeItem("isSignupVerify");
      }
      mounted.current = true;
    } else {
      // window.scrollTo(0, 0);
      // document.addEventListener("touchstart", onscroll, { passive: true });
    }
  });

  const [resendVerifyEmail] = useMutation(RESEND_VERIFY_EMAIL, {
    onCompleted: (res) => {
      setErrorMsg(`We have sent a link to ${loginEmail} to confirm your email address.
      Please check your inbox and confirm your email address.`);
      setIsSpinner(false);
      setIsDim(false);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleLogin = (emailVal, passVal) => {
    setIsSpinner(true);
    setIsDim(true);
    clientV3
      .mutate({
        mutation:
          localStorage.getItem("loginPanel") === "agent"
            ? AGENT_LOGIN_QUERY
            : FIREBASE_LOGIN_QUERY,
        variables: {
          input: {
            username: emailVal && emailVal !== "" ? emailVal : loginEmail,
            password: passVal && passVal !== "" ? passVal : loginPassword,
            device_type: "website",
            oneclick: false,
            client:
              clientTag && clientTag !== ""
                ? clientTag
                : isMobile
                ? "default"
                : "default",
          },
        },
      })
      .then((res) => {
        //Update Arranger ID Session
        const getVal =
          localStorage.getItem("loginPanel") === "agent"
            ? res?.data?.agentLogin?.user
            : res?.data?.firebaseLogin?.user;
        handleUpdateArrangerId(
          getVal && getVal.arranger ? getVal.arranger.id : null,
        );

        storeUserProfileInfo(res, localStorage.getItem("loginPanel"));
        setToLocalStorage(res);

        //if user is in-app after login redirect to in-app home page. else normal web home page
        if (isInApp) {
          window.location.href = `/${selectedLanguage}/in-app${window.location.search}`;
        } else if (isInWeb) {
          window.location.href = `/${selectedLanguage}/in-web${window.location.search}`;
        } else {
          window.location.href = `/${selectedLanguage}${window.location.search}`;
        }
        localStorage.getItem(IS_CUSTOMER) === "yes"
          ? gtmB2CLogin(true)
          : gtmB2BLogin(true);
        localStorage.getItem(IS_B2B) === "yes" &&
          gtmB2BClassType(
            JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY)) !== null &&
              JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY))
                .class_type &&
              JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY)).class_type
                .id,
          );
      })
      .catch((err) => {
        const verifyMsg = err.message.includes("Account Unverified");

        if (verifyMsg) {
          resendVerifyEmail({
            variables: {
              input: {
                email: emailVal && emailVal !== "" ? emailVal : loginEmail,
              },
            },
          });
          return;
        }

        const isAccountClose = err.message.includes(
          "Your account is temporarily closed. Please contact our customer service team",
        );
        const isLoginFailB2B = err.message.includes(
          "Login failed! Please use the B2C Customer login tab",
        );
        const isLoginFailB2C = err.message.includes(
          "Login failed! Use Agent login tab from flymya.com",
        );

        const errMsg = isAccountClose
          ? "Your account is temporarily closed. Please contact our partnership team for any assistance."
          : isLoginFailB2B
          ? "Login failed! You are using B2C Customer login. Please use the Customer login Panel instead."
          : isLoginFailB2C
          ? "Login failed! You are using B2B login. Please use the Agent login Panel instead."
          : "The user credentials were incorrect! please try again.";

        setShowB2BAccountBlockContactDetail(isAccountClose);
        setErrorMsg(errMsg);
        setIsSpinner(false);
        setIsDim(false);
      });
  };

  const checkAuth = () => {
    clientV3
      .mutate({
        mutation: CHECK_AUTH,
        fetchPolicy: "network-only",
      })
      .catch((err) => {
        console.log(err);
        forceLogout();

        //removeFromLocalStorage();
      });
  };

  const forceLogout = () => {
    clientV3
      .mutate({
        mutation: FORCE_LOGOUT,
        fetchPolicy: "network-only",
        variables: {
          input: {
            id: localStorage.getItem("userId"),
          },
        },
      })
      .then((res) => {
        removeFromLocalStorage();
      })
      .catch((err) => {
        console.log(err);
        removeFromLocalStorage();
      });
  };

  const getHighlightBorderColor = () => {
    if (isInWeb && clientTag === KBZSC_IN_WEB) {
      return "2px solid #0b55a8";
    }
    if (isInWeb && clientTag === VIBER_IN_WEB) {
      return "2px solid #794aff";
    }
    if (isInWeb && clientTag === CITY_REWARD_IN_WEB) {
      return "2px solid #6e51a2";
    }
    return "2px solid #00aeef";
  };

  const getVerifyLogo = () => {
    if (!isMobile || (isMobile && !isInApp)) return VerityTicketLogo;
    if (
      isInApp &&
      (clientTag === MPITESAN ||
        clientTag === CITIZENPAY ||
        clientTag === MOMONEY ||
        clientTag === AYAPAY)
    )
      return VerityTicketLogo;

    if (
      isInApp &&
      clientTag === KBZ &&
      pageName !== "Flight" &&
      pageName !== "Hotel" &&
      pageName !== "Bus" &&
      pageName !== "Car" &&
      pageName !== "Balloon" &&
      pageName !== "Promotions" &&
      pageName !== "Insurance" &&
      pageName !== "verifyTicket"
    ) {
      return VerityTicketLogo;
    }

    return VerityTicketLogoWhite;
  };

  const getMenuPhone = () => {
    if (!isMobile || (isMobile && !isInApp)) return "normalPhone";
    if (
      isInApp &&
      (clientTag === MPITESAN ||
        clientTag === CITIZENPAY ||
        clientTag === MOMONEY ||
        clientTag === AYAPAY)
    )
      return "normalPhone";

    return "normalPhoneWhite";
  };

  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [showBugReportModal, setShowBugReportModal] = useState(false);
  const [feedbackConfirmModal, setFeedbackConfirmModal] = useState(false);
  const [bookingError, setBookingError] = useState(false);
  const [flightMsg, setFlightMsg] = useState(null);
  const classes = BalloonHomeStyles();

  const sendGorgiaMessage = () => {
    localStorage.setItem("gorgiasHomeInfo", "");

    window &&
      window.GorgiasChat &&
      window.GorgiasChat.init().then(function () {
        window.GorgiasChat.open();
        let homeinfo = localStorage.getItem("gorgiasHomeInfo");
        if (homeinfo !== "") {
          window.location.href = `javascript:GorgiasChat.sendMessage(${JSON.stringify(
            homeinfo,
          )})`;
        }
      });
  };

  useEffect(() => {
    const eventName = selectedProduct?.includes("flight")
      ? pageName === "Flight"
        ? "flights_homepage_center_click"
        : ""
      : selectedProduct?.includes("bus")
      ? "buses_homepage_center_click"
      : selectedProduct?.includes("insurance")
      ? "insurance_homepage_center_click"
      : selectedProduct?.includes("tour-packages")
      ? "tour_homepage_center_click"
      : selectedProduct?.includes("car")
      ? "cars_homepage_center_click"
      : selectedProduct?.includes("balloon")
      ? "balloons_homepage_center_click"
      : selectedProduct?.includes("promotion")
      ? "promos_homepage_center_click"
      : "";
    if (eventName !== "") {
      //handleFlymyaSession();
      handleCreateUserAction({
        eventName: eventName,
      });
    }
  }, [selectedProduct]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={infoOpen}
        autoHideDuration={10000}
        onClose={() => setInfoOpen(false)}
        TransitionComponent={Slide}
      >
        <Alert
          onClose={() => setInfoOpen(false)}
          variant="filled"
          severity="warning"
        >
          {t(`paymentPageThree.email_verify`) !==
          "paymentPageThree.email_verify"
            ? t(`paymentPageThree.email_verify`)
            : ` We have sent a link to ${loginEmail} to confirm your email address. Please check your inbox and confirm your email address.`}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={loginSuccessOpen}
        autoHideDuration={10000}
        onClose={() => setLoginSuccessOpen(false)}
        TransitionComponent={Slide}
      >
        <Alert
          onClose={() => setLoginSuccessOpen(false)}
          variant="filled"
          severity="success"
        >
          Login Successfully!
        </Alert>
      </Snackbar>
      <div className="header-menu-wrapper">
        {!hideHeader && (
          <Grid
            item
            xs={12}
            sm={12}
            // md={
            //   props.match.params.locale && props.match.params.locale === "my-mm"
            //     ? 2
            //     : 3
            // }
            md={12}
            className={`mobile_homeMenu`}
            style={
              (pageName === "HotelSearch" ||
                pageName === "AboutUs" ||
                pageName === "HotelLongStayResult" ||
                pageName === "InsurancePolicy" ||
                pageName === "FlightResult" ||
                pageName === "BusResult" ||
                pageName === "Promotions" ||
                pageName === "LoyaltyProgram") &&
              !isMobile
                ? {
                    background: "#FFF",
                    boxShadow: !isProductHomePage ? "none" : "",
                    paddingTop: 12,
                    paddingBottom: 12,
                  }
                : {}
            }
          >
            {/* New Mobile Menu Flight-Bus-Hotel*/}
            <MediaQuery maxWidth={780}>
              {!(isMobile && pageName === "HotelSearch") && (
                <Link
                  to={{
                    pathname: isInApp
                      ? `/${selectedLanguage}/in-app`
                      : isInWeb
                      ? `/${selectedLanguage}/in-web`
                      : `/${selectedLanguage}`,
                    search: window.location.search,
                  }}
                  className="logo_anchor"
                >
                  <img
                    src={
                      !isInApp && !isInWeb
                        ? NewLogo
                        : (isInApp &&
                            clientTag !== MPITESAN &&
                            clientTag !== CITIZENPAY &&
                            clientTag !== AYAPAY &&
                            clientTag === KBZ &&
                            pageName === "Flight" &&
                            clientTag !== MOMONEY) ||
                          (isInWeb && clientTag === KBZSC_IN_WEB)
                        ? NewLogoWhite
                        : NewLogo
                    }
                    alt="Flymya.com"
                    className="logo"
                    width="83"
                    style={{ marginLeft: "0px" }}
                    height="44"
                  />
                  {props.logo &&
                    localStorage.getItem("clientActive") === "true" && (
                      <img
                        style={{
                          borderLeft: `2px solid ${theme.palette.primary.main}`,
                        }}
                        src={props.logo}
                        alt="KBZPay"
                        className="logo kbzLogo"
                        width="30"
                        height="30"
                      />
                    )}
                  {/* {window.location.pathname.includes("in-web") &&
                  clientTag === KBZSC_IN_WEB && (
                    <img
                      style={{
                        borderLeft: `2px solid ${theme.palette.primary.main}`,
                      }}
                      src={KBZPayLogo}
                      alt="KBZPay"
                      className="logo kbzLogo"
                      width="30"
                      height="30"
                    />
                  )}
                {window.location.pathname.includes("in-web") &&
                  clientTag === VIBER_IN_WEB && (
                    <img
                      style={{
                        borderLeft: `2px solid ${theme.palette.primary.main}`,
                      }}
                      src={ViberInWebLogo}
                      alt="Viber"
                      className="logo kbzLogo"
                      width="30"
                      height="30"
                    />
                  )}
                {window.location.pathname.includes("in-web") &&
                  clientTag === CITY_REWARD_IN_WEB && (
                    <img
                      style={{
                        borderLeft: `2px solid ${theme.palette.primary.main}`,
                      }}
                      src={CityRewardInWebLogo}
                      alt="Viber"
                      className="logo CRLogo"
                      width="30"
                      height="30"
                    />
                  )} */}
                </Link>
              )}
              {localStorage.getItem(IDENTIFIER) === "test@flymya.co" && (
                <div style={{ width: "25px", height: "25px" }}>
                  <img
                    src={
                      !isInApp
                        ? feedback
                        : clientTag === "wavemoney" ||
                          clientTag === "kbz" ||
                          clientTag === "onepay" ||
                          clientTag === "uabpay"
                        ? feedback
                        : feedback
                    }
                    onClick={() => {
                      setShowBugReportModal(true);
                    }}
                    alt="contact-us-icon"
                    style={{ width: "100%" }}
                  />
                  <FeedbackModal
                    showBugReportModal={showBugReportModal}
                    setShowBugReportModal={setShowBugReportModal}
                    showConfirmModal={() => setFeedbackConfirmModal(true)}
                    loginUserId={localStorage.getItem(LOGIN_USER_ID)}
                    clientTag={clientTag}
                  />
                  <FeedbackConfirmModal
                    confirmModal={feedbackConfirmModal}
                    setConfirmModal={setFeedbackConfirmModal}
                    bookingConfirm={true}
                    bookingError={false}
                    classes={classes.balloonAlertModal}
                    classPhone={classes.balloonPhoneNumber}
                  />
                </div>
              )}
              {/* <BlogMenuItem
                menuClass="menu"
                spanClass={myanmarFont}
                selectedLanguage={selectedLanguage}
                isInApp={isInApp}
                isInWeb={isInWeb}
                clientTag={clientTag}
                selectedProduct={selectedProduct}
                t={t}
              /> */}
              <Link
                to={{
                  pathname: isInApp
                    ? `/${selectedLanguage}/in-app/verify-ticket`
                    : isInWeb
                    ? `/${selectedLanguage}/in-web/verify-ticket`
                    : `/${selectedLanguage}/verify-ticket`,
                  search: window.location.search,
                }}
                className="verify_ticket"
                style={{ width: "28px", height: "28px", paddingBottom: "4px" }}
              >
                <img
                  src={getVerifyLogo()}
                  alt=""
                  // width={30}
                  // height={30}
                  style={{
                    width: "100%",
                  }}
                />
              </Link>
              {/* {(pageName === "Flight" ||
                pageName === "Hotel" ||
                pageName === "Bus" ||
                pageName === "Car" ||
                pageName === "Balloon" ||
                pageName === "Promotions") && (
                <a
                  href={//}
                  className="header_phone_region"
                  style={{
                    marginTop: "6px",
                    marginRight: "6px",
                  }}
                >
                  <div
                    onClick={() =>
                      pageName === "Hotel" &&
                      triggerEmail(pageName, //gorgiasInfo, isInApp)
                    }
                  >
                    <img
                      className={`menuPhone ${getMenuPhone()}`}
                      src={PhoneWebAppIcon}
                      alt=""
                    />
                  </div>
                </a>
              )} */}
              {/* End b2b v3 Login */}
              {/* {(pageName === "Flight" ||
                pageName === "Hotel" ||
                pageName === "Bus" ||
                pageName === "Car" ||
                pageName === "Balloon" ||
                pageName === "Promotions") && (
                <>
                  {!isInApp && !isInWeb && (
                    <img
                      style={{ marginRight: "6px" }}
                      src={GorgianWebAppIcon}
                      alt="chat"
                      height={23}
                      width={23}
                    />
                  )}
                  {isInApp && (
                    <GorgiasInAppIcon style={{ margin: "4px 6px 0 0" }} />
                  )}
                  {isInWeb && (
                    <GorgiasInAppIcon
                      isInWeb={isInWeb}
                      style={{ marginRight: "6px" }}
                    />
                  )}
                </>
              )} */}
              {/* replace with new contact icon */}
              {(pageName === "Flight" ||
                pageName === "Hotel" ||
                pageName === "Bus" ||
                pageName === "Car" ||
                pageName === "Balloon" ||
                pageName === "Promotions" ||
                pageName === "Insurance" ||
                pageName === "TravelServices" ||
                pageName === "HotelLongStayResult" ||
                pageName === "verifyTicket") && (
                <div style={{ width: "25px", height: "25px" }}>
                  <img
                    src={
                      !isInApp
                        ? PhoneMailIcon
                        : clientTag === "wavemoney" ||
                          clientTag === "kbz" ||
                          clientTag === "onepay" ||
                          clientTag === "uabpay"
                        ? PhoneMailIconWhite
                        : PhoneMailIcon
                    }
                    onClick={() => {
                      setShowContactUsModal(true);
                    }}
                    alt="contact-us-icon"
                    style={{ width: "100%" }}
                  />
                  <OfflineInternationalMsg
                    isInApp={isInApp}
                    clientTag={clientTag}
                    sendGorgiaMessage={sendGorgiaMessage}
                    // searchInfo={flightInfo}
                    productDetail={flightMsg}
                    showContactUsModal={showContactUsModal}
                    setShowContactUsModal={setShowContactUsModal}
                  />
                </div>
              )}
              <ReactFlags
                pageName="homeFlag"
                selectedLanguage={selectedLanguage}
                pathName={props.location}
                isHomePage
                className={{ color: "#000" }}
              />
              {isAuthenticated() && (
                <div
                  className="loyalty-program"
                  style={{
                    width: 25,
                    height: 25,
                    marginRight: 5,
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={LoyaltyProgramLogo}
                    alt="loyalty-program-logo"
                    onClick={() => setShowLoyaltyMenu(!showLoyaltyMenu)}
                    style={{ width: "100%" }}
                  />
                  <LoyaltyProgramMenu
                    showModal={showLoyaltyMenu}
                    setShowModal={setShowLoyaltyMenu}
                    isInApp={isInApp}
                    isInWeb={isInWeb}
                    selectedLanguage={selectedLanguage}
                  />
                </div>
              )}
              <div>
                <AgentLoginMenu
                  isInApp={isInApp}
                  selectedLanguage={selectedLanguage}
                  isShow={isShow}
                  setIsShow={setIsShow}
                  setIsCreateAcc={setIsCreateAcc}
                  isMobile={true}
                  isWhiteColor={isInApp}
                  pageName={pageName}
                />
              </div>
            </MediaQuery>

            {/* Tablet or Mobile */}
            {/* maxWidth={1224} original */}
            <MediaQuery maxWidth={0} minWidth={0}>
              {(!isInApp || (isInApp && HandleMenu === "true")) && (
                <Button
                  onClick={toggleDrawer}
                  id={
                    isInApp
                      ? ""
                      : pageName && pageName === "Flight"
                      ? "fl-home-btn-menuIcon"
                      : ""
                  }
                  className="menuIcon nopadding"
                >
                  <MenuIcon />
                </Button>
              )}
              <Drawer
                open={showMenu}
                onClick={toggleDrawer}
                className="showMenu"
              >
                <MenuList className="nopadding">
                  {/* {permissions && permissions.includes("Flights") && ( */}
                  <FlightMenuItem
                    menuClass="menu"
                    spanClass={myanmarFont}
                    selectedLanguage={selectedLanguage}
                    imgWidth="35"
                    isInApp={isInApp}
                    isInWeb={isInWeb}
                    clientTag={clientTag}
                    selectedProduct={selectedProduct}
                    fromPage={pageName}
                  />
                  {/* )} */}
                  {/* {permissions && permissions.includes("Buses") && (
                    <BusMenuItem
                      menuClass="menu"
                      spanClass={myanmarFont}
                      selectedLanguage={selectedLanguage}
                      imgWidth="35"
                      isInApp={isInApp}
                      isInWeb={isInWeb}
                      clientTag={clientTag}
                      selectedProduct={selectedProduct}
                    />
                  )} */}
                  {/* {permissions && permissions.includes("Hotels") && ( */}
                  <HotelLongMenuItem
                    menuClass="menu"
                    spanClass={myanmarFont}
                    selectedLanguage={selectedLanguage}
                    imgWidth="35"
                    isInApp={isInApp}
                    isInWeb={isInWeb}
                    clientTag={clientTag}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    setHotelType={setHotelType}
                    fromPage={pageName}
                  />
                  {/* )} */}
                  <TravelServicesMenuItem
                    menuClass="menu"
                    spanClass={myanmarFont}
                    selectedLanguage={selectedLanguage}
                    imgWidth="35"
                    isInApp={isInApp}
                    isInWeb={isInWeb}
                    clientTag={clientTag}
                    selectedProduct={selectedProduct}
                    fromPage={pageName}
                  />
                  {/* {permissions && permissions.includes("Car Rentals") && ( */}
                  <CarMenuItem
                    menuClass="menu"
                    spanClass={myanmarFont}
                    selectedLanguage={selectedLanguage}
                    imgWidth="30"
                    isInApp={isInApp}
                    clientTag={clientTag}
                    selectedProduct={selectedProduct}
                    fromPage={pageName}
                  />
                  {/* )} */}

                  {/* {permissions && permissions.includes("Balloons") && ( */}
                  <BalloonMenuItem
                    menuClass="menu"
                    spanClass={myanmarFont}
                    selectedLanguage={selectedLanguage}
                    imgWidth="30"
                    isInApp={isInApp}
                    clientTag={clientTag}
                    fromPage={pageName}
                  />
                  {/* )} */}
                  {/* {permissions && permissions.includes("Blog") && ( */}
                  {/* <BlogMenuItem
                    menuClass="menu"
                    spanClass={myanmarFont}
                    selectedLanguage={selectedLanguage}
                    isInApp={isInApp}
                    isInWeb={isInWeb}
                    clientTag={clientTag}
                    selectedProduct={selectedProduct}
                    t={t}
                  /> */}
                  {/* )} */}
                  {/* {permissions && permissions.includes("Promotions") && ( */}
                  <PromotionMenuItem
                    menuClass="menu"
                    spanClass={myanmarFont}
                    selectedLanguage={selectedLanguage}
                    isInApp={isInApp}
                    clientTag={clientTag}
                    selectedProduct={selectedProduct}
                    fromPage={pageName}
                  />
                  {/* )} */}
                </MenuList>
              </Drawer>
              {(!isInApp || (isInApp && HandleFlag === "true")) && (
                <ReactFlags
                  pageName="homeFlag"
                  selectedLanguage={selectedLanguage}
                  pathName={props.location}
                />
              )}
            </MediaQuery>

            {!isInApp && (
              <MediaQuery minWidth={781}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item md={2} className="header-mainlogo">
                    <Link
                      to={{
                        pathname: isInWeb
                          ? `/${selectedLanguage}/in-web${
                              pageName && pageName === "Hotel" ? "/hotel" : ""
                            }`
                          : `/${selectedLanguage}${
                              pageName && pageName === "Hotel" ? "/hotel" : ""
                            }`,
                        search: window.location.search,
                      }}
                    >
                      <img
                        // style={
                        //   selectedLanguage === MY_MM ? { marginTop: "29px" } : {}
                        // }
                        //src={NewLogoWhite}
                        src={NewLogo}
                        alt="Flymya.com"
                        className="logo"
                      />
                      {props.logo &&
                        localStorage.getItem("clientActive") === "true" && (
                          <img
                            style={{
                              borderLeft: `2px solid ${theme.palette.primary.main}`,
                            }}
                            src={props.logo}
                            alt="KBZPay"
                            className="logo kbzLogo"
                          />
                        )}
                      {/* {isInWeb && clientTag === KBZSC_IN_WEB && (
                        <img
                          style={
                            selectedLanguage === MY_MM
                              ? {
                                  marginTop: "29px",
                                  borderLeft: `2px solid ${theme.palette.primary.main}`,
                                }
                              : {
                                  borderLeft: `2px solid ${theme.palette.primary.main}`,
                                }
                          }
                          //src={NewLogoWhite}
                          src={KBZPayLogo}
                          alt="KBZPay"
                          className="logo kbzLogo"
                        />
                      )}
                      {isInWeb && clientTag === VIBER_IN_WEB && (
                        <img
                          style={
                            selectedLanguage === MY_MM
                              ? {
                                  // marginTop: "29px",
                                  borderLeft: `2px solid ${theme.palette.primary.main}`,
                                }
                              : {
                                  borderLeft: `2px solid ${theme.palette.primary.main}`,
                                }
                          }
                          src={ViberInWebLogo}
                          alt="Viber"
                          className="logo kbzLogo"
                        />
                      )}
                      {isInWeb && clientTag === CITY_REWARD_IN_WEB && (
                        <img
                          style={
                            selectedLanguage === MY_MM
                              ? {
                                  // marginTop: "29px",
                                  borderLeft: `2px solid ${theme.palette.primary.main}`,
                                }
                              : {
                                  borderLeft: `2px solid ${theme.palette.primary.main}`,
                                }
                          }
                          src={CityRewardInWebLogo}
                          alt="Viber"
                          className="logo kbzLogo"
                        />
                      )} */}
                    </Link>
                  </Grid>
                  <Grid
                    item
                    md={isProductHomePage ? 4 : 6}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      paddingLeft: isProductHomePage ? 0 : 20,
                    }}
                  >
                    <MediaQuery minWidth={991}>
                      {!isProductHomePage ? (
                        <div className="product-wrap">
                          <Link
                            style={{
                              fontSize: 16,
                              // fontFamily: "Poppins",
                              cursor: "pointer",
                              color:
                                selectedProduct === "flight"
                                  ? "#00AEEF"
                                  : "#1A1A1A",
                              borderBottom:
                                selectedProduct === "flight"
                                  ? `2px solid #00AEEF`
                                  : "none",
                            }}
                            to={{
                              pathname: urlFlightPath,
                              search: currentUrlParams.toString(),
                            }}
                            onClick={() =>
                              setSelectedProduct && setSelectedProduct("flight")
                            }
                          >
                            {t("masterHomePage.flight") !==
                            "masterHomePage.flight"
                              ? t("masterHomePage.flight")
                              : "Flights"}
                          </Link>
                          <div className="menu-container">
                            <span
                              className="textSelect"
                              style={{
                                fontSize: 16,
                                cursor: "pointer",
                                color:
                                  selectedProduct === "hotel"
                                    ? "#00AEEF"
                                    : "#1A1A1A",
                                borderBottom:
                                  selectedProduct === "hotel"
                                    ? `2px solid #00AEEF`
                                    : "none",
                              }}
                            >
                              {t("masterHomePage.hotel") !==
                              "masterHomePage.hotel"
                                ? t("masterHomePage.hotel")
                                : "Hotels"}
                            </span>
                            <div className="menus">
                              <Link
                                to={{
                                  pathname: urlHotelPath,
                                  search: currentUrlParams.toString(),
                                }}
                                onClick={() =>
                                  setSelectedProduct &&
                                  setSelectedProduct("hotel")
                                }
                              >
                                {t("masterHomePage.domestic_hotel") !==
                                "masterHomePage.domestic_hotel"
                                  ? t("masterHomePage.domestic_hotel")
                                  : "Domestic Hotel"}
                              </Link>
                              <Link
                                to={{
                                  pathname: urlHotelPath,
                                  search: currentUrlParams.toString(),
                                }}
                                onClick={() =>
                                  setSelectedProduct &&
                                  setSelectedProduct("hotel")
                                }
                              >
                                {t("masterHomePage.international_hotel") !==
                                "masterHomePage.international_hotel"
                                  ? t("masterHomePage.international_hotel")
                                  : "International Hotel"}
                              </Link>
                              <Link
                                to={{
                                  pathname: urlLongPath,
                                  search: currentUrlParams.toString(),
                                }}
                              >
                                {t("hotel.hotel_long_stay") !==
                                "hotel.hotel_long_stay"
                                  ? t("hotel.hotel_long_stay")
                                  : "Hotel Long Stay"}
                              </Link>
                            </div>
                          </div>
                          <Link
                            style={{
                              fontSize: 16,
                              // fontFamily: "Poppins",
                              cursor: "pointer",
                              color:
                                selectedProduct === "bus"
                                  ? "#00AEEF"
                                  : "#1A1A1A",
                              borderBottom:
                                selectedProduct === "bus"
                                  ? `2px solid #00AEEF`
                                  : "none",
                            }}
                            to={{
                              pathname: urlBusPath,
                              search: currentUrlParams.toString(),
                            }}
                            onClick={() =>
                              setSelectedProduct && setSelectedProduct("bus")
                            }
                          >
                            {t("masterHomePage.bus") !== "masterHomePage.bus"
                              ? t("masterHomePage.bus")
                              : "Buses"}
                          </Link>
                          <div className="menu-container">
                            <span
                              className="textSelect"
                              style={{
                                fontSize: 16,
                                // fontFamily: "Poppins",
                                cursor: "pointer",
                                color:
                                  selectedProduct === "travel-services"
                                    ? "#00AEEF"
                                    : "#1A1A1A",
                                borderBottom:
                                  selectedProduct === "travel-services"
                                    ? `2px solid #00AEEF`
                                    : "none",
                              }}
                            >
                              {t("travelServices.travel_services_title") !==
                              "travelServices.travel_services_title"
                                ? t("travelServices.travel_services_title")
                                : "Travel Services"}
                            </span>
                            <div className="menus">
                              <Link
                                to={{
                                  pathname: `${urlTravelServicePath}/${TRAVEL_VISA_SERVICE}`,
                                  search: currentUrlParams.toString(),
                                }}
                                onClick={() =>
                                  setSelectedProduct &&
                                  setSelectedProduct("travel-services")
                                }
                              >
                                {t("travelServices.visa_service") !==
                                "travelServices.visa_service"
                                  ? t("travelServices.visa_service")
                                  : "Visa Service"}
                              </Link>
                              <Link
                                to={{
                                  pathname: `${urlTravelServicePath}/${TRAVEL_VIP_SERVICE}`,
                                  search: currentUrlParams.toString(),
                                }}
                                onClick={() =>
                                  setSelectedProduct &&
                                  setSelectedProduct("travel-services")
                                }
                              >
                                {t("travelServices.vip_service") !==
                                "travelServices.vip_service"
                                  ? t("travelServices.vip_service")
                                  : "Airport VIP Services"}
                              </Link>
                            </div>
                          </div>
                          <Link
                            style={{
                              fontSize: 16,
                              // fontFamily: "Poppins",
                              cursor: "pointer",
                              color:
                                selectedProduct === "insurance"
                                  ? "#00AEEF"
                                  : "#1A1A1A",
                              borderBottom:
                                selectedProduct === "insurance"
                                  ? `2px solid #00AEEF`
                                  : "none",
                            }}
                            to={{
                              pathname: `${urlInsurancePath}`,
                              search: currentUrlParams.toString(),
                            }}
                            onClick={() =>
                              setSelectedProduct &&
                              setSelectedProduct("insurance")
                            }
                          >
                            {t("travelServices.travel_insurance") !==
                            "travelServices.travel_insurance"
                              ? t("travelServices.travel_insurance")
                              : "Insurance"}
                          </Link>
                          <div className="menu-container">
                            <span className="dots">•••</span>
                            <div className="menus">
                              <Link
                                to={{
                                  pathname: `${urlTourPath}`,
                                  search: currentUrlParams.toString(),
                                }}
                              >
                                {t("travelServices.tour_packages") !==
                                "travelServices.tour_packages"
                                  ? t("travelServices.tour_packages")
                                  : "Tour Packages"}
                              </Link>
                              <Link
                                to={{
                                  pathname: `${urlCarPath}`,
                                  search: currentUrlParams.toString(),
                                }}
                                onClick={() =>
                                  setSelectedProduct &&
                                  setSelectedProduct("car-rental")
                                }
                              >
                                {t("masterHomeSecondPage.car_rental") !==
                                "masterHomeSecondPage.car_rental"
                                  ? t("masterHomeSecondPage.car_rental")
                                  : "Cars"}
                              </Link>
                              <Link
                                to={{
                                  pathname: `${urlBalloonPath}`,
                                  search: currentUrlParams.toString(),
                                }}
                                onClick={() =>
                                  setSelectedProduct &&
                                  setSelectedProduct("balloon")
                                }
                              >
                                {t("masterHomePage.balloon") !==
                                "masterHomePage.balloon"
                                  ? t("masterHomePage.balloon")
                                  : "Balloons"}
                              </Link>
                              <Link
                                to={{
                                  pathname: `${urlPromotionPath}`,
                                  search: currentUrlParams.toString(),
                                }}
                              >
                                {t("masterHomeSecondPage.promotion") !==
                                "masterHomeSecondPage.promotion"
                                  ? t("masterHomeSecondPage.promotion")
                                  : "Promos"}
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="d-flex justify-content-end"
                          style={{ width: "100%" }}
                        >
                          <p className="flymyaSlogan">Travel is easy with us</p>
                        </div>
                      )}
                    </MediaQuery>
                    {/* <p className="flymyaSlogan">Travel is easy with us</p> */}
                  </Grid>
                  <Grid item md={4}>
                    <div className="wrap">
                      {localStorage.getItem(IDENTIFIER) ===
                        "test@flymya.co" && (
                        <div
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={
                              !isInApp
                                ? feedback
                                : clientTag === "wavemoney" ||
                                  clientTag === "kbz" ||
                                  clientTag === "onepay"
                                ? feedback
                                : feedback
                            }
                            onClick={() => {
                              setShowBugReportModal(true);
                            }}
                            alt="contact-us-icon"
                            style={{ width: "100%" }}
                          />
                          <FeedbackModal
                            showBugReportModal={showBugReportModal}
                            setShowBugReportModal={setShowBugReportModal}
                            showConfirmModal={(err) => {
                              setBookingError(err);
                              setFeedbackConfirmModal(true);
                            }}
                            loginUserId={localStorage.getItem(LOGIN_USER_ID)}
                            clientTag={clientTag}
                          />
                          <FeedbackConfirmModal
                            confirmModal={feedbackConfirmModal}
                            setConfirmModal={setFeedbackConfirmModal}
                            bookingConfirm={!bookingError}
                            bookingError={bookingError}
                            classes={classes.balloonAlertModal}
                            classPhone={classes.balloonPhoneNumber}
                          />
                        </div>
                      )}
                      {/* <div> */}
                      {/* {permissions && permissions?.includes("Blog") && ( */}
                      {/* <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <BlogMenuItem
                            menuClass="menu pull-right"
                            spanClass={myanmarFont}
                            selectedLanguage={selectedLanguage}
                            isInWeb={isInWeb}
                            t={t}
                          />
                        </div> */}
                      {/* )} */}
                      {/* </div> */}
                      <div>
                        <MenuItem className="menu pull-right web-flag">
                          {!isInApp && (
                            <ReactFlags
                              pageName="homeFlag"
                              selectedLanguage={selectedLanguage}
                              pathName={props.location}
                            />
                          )}
                        </MenuItem>
                      </div>
                      <div
                        className={
                          selectedLanguage === "en"
                            ? "ticketEN header-verifyticket"
                            : "ticketMM header-verifyticket"
                        }
                        onClick={() =>
                          navigate({
                            pathname: isInApp
                              ? `/${selectedLanguage}/in-app/verify-ticket`
                              : isInWeb
                              ? `/${selectedLanguage}/in-web/verify-ticket`
                              : `/${selectedLanguage}/verify-ticket`,
                            search: window.location.search,
                          })
                        }
                      >
                        <span>Verify Ticket</span>
                      </div>
                      <div
                        onClick={() => {
                          setShowContactUsModal(true);
                        }}
                        className="header-contactus"
                      >
                        <span>
                          {t("masterHomeSecondPage.contact_us") !==
                          "masterHomeSecondPage.contact_us"
                            ? t("masterHomeSecondPage.contact_us")
                            : "Contact Us"}
                        </span>
                      </div>
                      <OfflineInternationalMsg
                        sendGorgiaMessage={sendGorgiaMessage}
                        // searchInfo={flightInfo}
                        productDetail={flightMsg}
                        showContactUsModal={showContactUsModal}
                        setShowContactUsModal={setShowContactUsModal}
                      />
                      {/* open login feature for all in-web (PO-nick,kaung)*/}
                      {/* {(!isInWeb ||
                        (isInWeb && clientTag === CITY_REWARD_IN_WEB) ||
                        (isInWeb && clientTag === VIBER_IN_WEB)) && ( */}
                      {isAuthenticated() && (
                        <div
                          className="loyalty-program"
                          style={{
                            width: 25,
                            height: 25,
                            marginRight: 5,
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={LoyaltyProgramLogo}
                            alt="loyalty-program-logo"
                            onClick={() => setShowLoyaltyMenu(!showLoyaltyMenu)}
                            style={{ width: "100%" }}
                          />
                          <LoyaltyProgramMenu
                            showModal={showLoyaltyMenu}
                            setShowModal={setShowLoyaltyMenu}
                            isInApp={isInApp}
                            isInWeb={isInWeb}
                            selectedLanguage={selectedLanguage}
                          />
                        </div>
                      )}
                      <div>
                        <MenuItem className="menu pull-right agent-login">
                          <AgentLoginMenu
                            selectedLanguage={selectedLanguage}
                            isShow={isShow}
                            setIsShow={setIsShow}
                            setIsCreateAcc={setIsCreateAcc}
                            isMobile={false}
                            pageName={pageName}
                          />
                        </MenuItem>
                      </div>
                      {!isAuthenticated() && (
                        <div
                          className="header-createaccount"
                          onClick={() => {
                            setIsShow(true);
                            setIsCreateAcc(true);

                            //remove the localStorage variable from signup otp
                            localStorage.removeItem(LOGIN_EMAIL);
                            localStorage.removeItem(LOGIN_PHONE);
                            localStorage.removeItem(LOGIN_NAME);
                          }}
                        >
                          <span>Register</span>
                        </div>
                      )}
                      {/* )} */}
                    </div>
                  </Grid>
                </Grid>
              </MediaQuery>
            )}
            {/* {!isInApp && (
              <MediaQuery maxWidth={780} minWidth={601}>
                <Link
                  to={{
                    pathname: isInWeb
                      ? `/${selectedLanguage}/in-web${
                          pageName && pageName === "Hotel" ? "/hotel" : ""
                        }`
                      : `/${selectedLanguage}${
                          pageName && pageName === "Hotel" ? "/hotel" : ""
                        }`,
                    search: window.location.search,
                  }}
                >
                  <img src={NewLogoWhite} alt="Flymya.com" className="logo" />
                  {isInWeb && clientTag === KBZSC_IN_WEB && (
                    <img
                      style={
                        selectedLanguage === MY_MM ? { paddingTop: "29px" } : {}
                      }
                      src={KBZPayLogo}
                      alt="KBZPay"
                      className="logo kbzLogo"
                    />
                  )}
                </Link>
              </MediaQuery>
            )} */}
          </Grid>
        )}
      </div>

      {/* Desktop or laptop */}
      {/* currently show all icons for defaults, disabled all permissions conditions for now*/}
      {/* original minDeviceWdith={1224} */}
      {pageName !== "ContactUs" &&
        pageName !== "AboutUs" &&
        pageName !== "HotelLongStayResult" &&
        pageName !== "InsurancePolicy" &&
        pageName !== "FlightResult" &&
        pageName !== "BusResult" &&
        pageName !== "Promotions" &&
        pageName !== "LoyaltyProgram" &&
        type !== "RewardsPageHeader" &&
        (!isAuthenticated() || (isAuthenticated() && !EmployeeLogin())) && (
          <MediaQuery minDeviceWidth={768}>
            <HomeMenuWeb
              params={params}
              pageName={pageName}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              myanmarFont={myanmarFont}
              isInWeb={isInWeb}
              isInApp={isInApp}
              selectedLanguage={selectedLanguage}
              setHotelType={setHotelType}
              setShowTestModal={setShowTestModal}
              getHighlightBorderColor={getHighlightBorderColor}
              clientTag={clientTag}
            />
          </MediaQuery>
        )}

      <LoginSignupModal
        clientTag={clientTag}
        selectedLanguage={selectedLanguage}
        isInApp={isInApp}
        isInWeb={isInWeb}
        isShow={isShow}
        setIsShow={setIsShow}
        isCreateAcc={isCreateAcc}
        setIsSpinner={setIsSpinner}
        isDim={isDim}
        setIsDim={setIsDim}
        loginEmail={loginEmail}
        setLoginEmail={setLoginEmail}
        loginPassword={loginPassword}
        setLoginPassword={setLoginPassword}
        handleLogin={handleLogin}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        isForget={isForget}
        isSpinner={isSpinner}
        isCheck={isCheck}
        setIsCheck={setIsCheck}
        setIsForget={setIsForget}
        showB2BAccountBlockContactDetail={showB2BAccountBlockContactDetail}
        page="home"
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
      />
      <TestCheckoutModal
        showModal={showTestModal}
        setShowModal={setShowTestModal}
      />
    </>
  );
};

export default HomeMenu;
