import { gql } from "apollo-boost";

const GET_COMPANY = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    company(orderBy: $orderBy, first: $first, page: $page, filter: $filter) {
      data {
        id
        name
        internal_id
        class_type {
          id
          name
        }
        company_accounts {
          id
          account_code
          company {
            id
            name
          }
          name
          account_function
          type
          balance
          currency
          credit_type {
            id
            name
          }
          max_amount
          date
          repeats
          repeat_num
        }
        arrangers {
          id
          prefix
          first_name
          last_name
          arranger_phones {
            id
            phone_prefix
            phone
            is_verified
            is_primary
          }
          arranger_emails {
            id
            email
            is_verified
            is_primary
          }
        }
        aliases {
          id
          name
          domain
          sales
          supplier_account
          customer_account
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_RESERVATIONS = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    reservationsByArranger(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        total {
          id
          show_fare_amount
          show_fare_currency
        }
        balance {
          id
          show_fare_amount
          show_fare_currency
        }
        currency
        created_at
        status {
          id
          name
        }
        products {
          id
          status_name
          product_type {
            id
            name
            order
          }
          external_id
        }
        transactions {
          id
          status_name
        }
        created_by {
          id
          name
          email
        }
      }
      paginatorInfo {
        count
        currentPage
        perPage
      }
    }
  }
`;

const GET_PRODUCT_BY_RESERVATION_ID = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    products(filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
      data {
        id
        status_name
        product_type {
          id
          name
          order
        }
        sales_category {
          id
          name
        }
        external_id
        description
        travel_date
        date_of_sale
        issued_date
        supplier {
          id
          name
          company {
            id
            name
          }
        }
        operator {
          id
          code
          name
        }
        product_meta {
          id
          key
          value
          product {
            id
          }
          roundtrip
        }
        selling_price {
          id
          physical_amount
          physical_currency
        }
        gross_base {
          id
          physical_amount
          physical_currency
        }
        taxes {
          id
          physical_amount
          physical_currency
        }
        service_fees {
          id
          physical_amount
          physical_currency
        }
        passengers {
          id
          prefix
          first_name
          last_name
          phone
          email
          nationality {
            id
            name
          }
          dob
          nrc
          passport
          passport_exp
          address
          township {
            id
            name
            city_id
            city {
              id
              name
              region_id
              region {
                id
                name
              }
            }
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
      }
    }
  }
`;

const GET_TRANSACTION_BY_RESERVATION_ID = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    transactions(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        status_name
        external_id
        payment_date
        payer_name
        payment_method {
          id
          name
          currencies {
            code
            name
          }
        }
        company_account {
          id
          name
          currency
        }
        payment_method_active
        amount {
          id
          physical_amount
          physical_currency
        }
        remarks
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
      }
    }
  }
`;

const GET_PASSENGER_BY_RESERVATION_ID = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    reservationsByArranger(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        arranger {
          id
          prefix
          first_name
          last_name
          passport
          nrc
        }
        products {
          id
        }
      }
    }
  }
`;

const GET_TRANSACTIONS = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    transactionsByPeople(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        transaction_id
        reservation {
          id
        }
        transaction_type {
          id
          name
        }
        amount {
          id
          physical_amount
          physical_currency
        }
        payment_date
        status_name
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_B2C_TRANSACTIONS = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    transactionsByB2C(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        transaction_id
        reservation {
          id
        }
        transaction_type {
          id
          name
        }
        amount {
          id
          physical_amount
          physical_currency
        }
        payment_date
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const E_TICKET_DOWNLOAD_LINK = gql`
  mutation ($reservation_id: ID!) {
    eticketExport(reservation_id: $reservation_id) {
      status
      message
    }
  }
`;

const CREATE_ARRANGER = gql`
  mutation ($input: arrangerInput) {
    createArranger(input: $input) {
      id
      users {
        id
        name
        email
        phone
        company {
          id
          name
          arrangers {
            id
            prefix
            first_name
            last_name
            arranger_phones {
              id
              phone_prefix
              phone
              is_verified
              is_primary
            }
            arranger_emails {
              id
              email
              is_verified
              is_primary
            }
            nationality {
              id
              name
            }
            dob
            passport
            passport_exp
            nrc
            address
            township {
              id
              name
              city {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
        }
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        arranger {
          id
          prefix
          first_name
          last_name
          arranger_phones {
            id
            phone_prefix
            phone
            is_verified
            is_primary
          }
          arranger_emails {
            id
            email
            is_verified
            is_primary
          }
          nationality {
            id
            name
          }
          company {
            id
            name
            active
            class_type {
              id
              name
            }
          }
          dob
          passport
          passport_exp
          nrc
          address
          township {
            id
            name
            city {
              id
              name
              region {
                id
                name
              }
            }
          }
          #loyalty_program {
          #  id
          #  sign_up
          #  logged_in_mobile
          #  joined_experience_myanmar
          #  invited_friend
          #  arranger {
          #    id
          #  }
          #  referral_by {
          #    id
          #  }
          #  booked_flight
          #  booked_bus
          #  booked_hotel
          #  added_friend
          #}
        }
      }
    }
  }
`;

const CREATE_ARRANGER_B2C_PROFILE = gql`
  mutation ($input: arrangerB2cProfileInput) {
    createArrangerB2cProfile(input: $input) {
      id
      user {
        id
        name
        email
        phone
        company {
          id
          name
          arrangers {
            id
            prefix
            first_name
            last_name
            arranger_phones {
              id
              phone_prefix
              phone
              is_verified
              is_primary
            }
            arranger_emails {
              id
              email
              is_verified
              is_primary
            }
            nationality {
              id
              name
            }
            dob
            passport
            passport_exp
            nrc
            address
            township {
              id
              name
              city {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
        }
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        arranger {
          id
          prefix
          first_name
          last_name
          arranger_phones {
            id
            phone_prefix
            phone
            is_verified
            is_primary
          }
          arranger_emails {
            id
            email
            is_verified
            is_primary
          }
          nationality {
            id
            name
          }
          company {
            id
            name
            active
            class_type {
              id
              name
            }
          }
          dob
          passport
          passport_exp
          nrc
          address
          township {
            id
            name
            city {
              id
              name
              region {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation deleteAccount {
    deleteAccount {
      id
    }
  }
`;

const CHECK_AUTH = gql`
  mutation {
    checkAuth {
      status
      message
    }
  }
`;
const FORCE_LOGOUT = gql`
  mutation ($input: forceLogoutInput) {
    forceLogout(input: $input) {
      id
      email
    }
  }
`;

const GET_USER_BY_ID = gql`
  mutation userProfile {
    userProfile {
      id
      email
      phone
      company {
        id
        name
      }
      arranger {
        id
        prefix
        first_name
        last_name
        nationality {
          id
          name
        }
        dob
        nrc
        passport
        passport_exp
        address
        township {
          id
          name
          city {
            id
            name
            region {
              id
              name
            }
          }
        }
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
      roles {
        id
        name
        permissions {
          id
          name
        }
      }
      login_types {
        id
        name
      }
    }
  }
`;

const GET_PRODUCT_MENU_SHOWHIDE = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    productMenu(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        name
        show
      }
    }
  }
`;

const BOOKING_HISTORY_EXPORT = gql`
  mutation ($input: bookingHistoryExportInput) {
    bookingHistoryExport(input: $input) {
      status
      message
      code
    }
  }
`;

const TRANSACTIONS_HISTORY_EXPORT = gql`
  mutation ($input: transHistoryExportInput) {
    transHistoryExport(input: $input) {
      status
      message
      code
    }
  }
`;

export {
  GET_COMPANY,
  GET_RESERVATIONS,
  GET_TRANSACTIONS,
  GET_B2C_TRANSACTIONS,
  GET_PRODUCT_BY_RESERVATION_ID,
  GET_TRANSACTION_BY_RESERVATION_ID,
  GET_PASSENGER_BY_RESERVATION_ID,
  E_TICKET_DOWNLOAD_LINK,
  DELETE_ACCOUNT,
  CREATE_ARRANGER,
  CREATE_ARRANGER_B2C_PROFILE,
  CHECK_AUTH,
  FORCE_LOGOUT,
  GET_USER_BY_ID,
  GET_PRODUCT_MENU_SHOWHIDE,
  BOOKING_HISTORY_EXPORT,
  TRANSACTIONS_HISTORY_EXPORT,
};
