import { gql } from "apollo-boost";

const CREATE_ARRANGER = gql`
  mutation ($input: arrangerInput) {
    createArranger(input: $input) {
      id
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
      }
      company {
        id
        name
        internal_id
      }
    }
  }
`;

const CREATE_ARRANGER_TRAVELLER = gql`
  mutation addArrangerTraveller($arranger_id: ID!, $traveller_id: ID!) {
    addArrangerTraveller(
      arranger_id: $arranger_id
      traveller_id: $traveller_id
    ) {
      arranger {
        id
        phone
        email
      }
      traveller {
        id
        prefix
        first_name
        last_name
        phone
        email
        nationality {
          id
          name
        }
        dob
        passport
        passport_exp
      }
    }
  }
`;

const ARRANGER_LOGIN = gql`
  mutation addArrangerLogin($arranger_id: ID!, $login_id: ID!) {
    addArrangerLogin(arranger_id: $arranger_id, login_id: $login_id) {
      arranger {
        id
        phone
        email
      }
      user {
        id
        name
        email
      }
    }
  }
`;
const CREATE_TRAVELLER = gql`
  mutation ($input: travellerInput) {
    createTraveller(input: $input) {
      id
      first_name
      last_name
      dob
      passport
      passport_exp
      nationality {
        id
        name
      }
    }
  }
`;
const ADD_ARRANGER_TRAVELLER = gql`
  mutation addArrangerTraveller($arranger_id: ID!, $traveller_id: ID!) {
    addArrangerTraveller(
      arranger_id: $arranger_id
      traveller_id: $traveller_id
    ) {
      arranger {
        id
        phone
        email
      }
      traveller {
        id
        prefix
        first_name
        last_name
        phone
        email
        nationality {
          id
          name
        }
        dob
        passport
        passport_exp
      }
    }
  }
`;

const ADD_ARRANGER_COMPANY = gql`
  mutation addArrangerCompany($arranger_id: ID!, $company_id: ID!) {
    addArrangerCompany(arranger_id: $arranger_id, company_id: $company_id) {
      arranger {
        id
        phone
        email
      }
      company {
        id
        name
      }
    }
  }
`;
const GET_LOGIN_TYPE = gql`
  query loginType {
    loginType {
      id
      name
    }
  }
`;
const ADD_ARRANGER_LOGIN = gql`
  mutation addArrangerLogin($arranger_id: ID!, $login_id: ID!) {
    addArrangerLogin(arranger_id: $arranger_id, login_id: $login_id) {
      arranger {
        id
        phone
        email
      }
      user {
        id
        name
        email
      }
    }
  }
`;

const CHECK_ARRANGER_EMAIL = gql`
  query ($email: Email!) {
    arrangerEmail(email: $email) {
      id
      email
      is_primary
      is_verified
      arranger {
        id
        first_name
        last_name
        users {
          id
          name
          email
          phone
          roles {
            id
            name
          }
          login_types {
            id
            name
          }
        }
      }
    }
  }
`;

const CHECK_ARRANGER_PHONE = gql`
  query ($input: arrangerPhoneInput!) {
    arrangerPhone(input: $input) {
      id
      phone
      is_primary
      is_verified
      arranger {
        id
        first_name
        last_name
        users {
          id
          name
          email
          login_types {
            id
            name
          }
        }
      }
    }
  }
`;

const CREATE_ARRANGER_PHONE = gql`
  mutation createArrangerPhone($input: arrangerPhoneCreateInput) {
    createArrangerPhone(input: $input) {
      id
      phone_prefix
      phone
      is_primary
      is_verified
      arranger {
        id
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
    }
  }
`;

const CREATE_ARRANGER_EMAIL = gql`
  mutation createArrangerEmail($input: arrangerEmailCreateInput) {
    createArrangerEmail(input: $input) {
      id
      email
      is_primary
      is_verified
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const EDIT_ARRANGER_EMAIL = gql`
  mutation editArrangerEmail($input: arrangerEmailEditInput) {
    editArrangerEmail(input: $input) {
      id
      email
      is_primary
      is_verified
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const DELETE_ARRANGER_PHONE = gql`
  mutation deleteArrangerPhone($id: ID!) {
    deleteArrangerPhone(id: $id) {
      id
      arranger {
        id
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
    }
  }
`;

const DELETE_ARRANGER_EMAIL = gql`
  mutation deleteArrangerEmail($id: ID!) {
    deleteArrangerEmail(id: $id) {
      id
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const ARRANGER_FILTER = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangers(filter: $filter, page: $page, first: $first, orderBy: $orderBy) {
      data {
        id
        deleted_at
        user {
          id
          email
          roles {
            id
            name
          }
        }
        company {
          id
          name
          arrangers {
            id
            company {
              id
              name
              class_type {
                id
                name
              }
            }
          }
          class_type {
            id
          }
        }
        prefix
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
        nationality {
          id
          name
        }
        dob
        passport
        passport_exp
        address
        township {
          id
          name
          city_id
          city {
            id
            name
            region_id
            region {
              id
              name
              country {
                id
                name
                iso_code_2
                iso_code_3
                currency_code
                nationality
                phone
              }
            }
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const MAKE_PRIMARY_ARRANGER_PHONE = gql`
  mutation ($input: makePrimaryArrangerPhoneInput!) {
    makePrimaryArrangerPhone(input: $input) {
      id
      arranger {
        id
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
    }
  }
`;

const MAKE_PRIMARY_ARRANGER_EMAIL = gql`
  mutation ($input: makePrimaryArrangerEmailInput!) {
    makePrimaryArrangerEmail(input: $input) {
      id
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const GET_ARRANGERS_BY_UNVERIFY_PHONE = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangersByUnverifiedPhone(
      filter: $filter
      page: $page
      first: $first
      orderBy: $orderBy
    ) {
      id
      total_reservation
      last_booking_date
      last_signin_date
      users {
        id
        email
        roles {
          id
          name
        }
      }
      users {
        id
        email
        roles {
          id
          name
        }
      }
      company {
        id
        name
        arrangers {
          id
          company {
            id
            name
            class_type {
              id
              name
            }
          }
        }
      }
      prefix
      first_name
      last_name
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
      }
      nationality {
        id
        name
      }
      dob
      passport
      passport_exp
      address
      township {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
            country {
              id
              name
              iso_code_2
              iso_code_3
              currency_code
              nationality
              phone
            }
          }
        }
      }
    }
  }
`;

const GET_ARRANGERS_BY_UNVERIFY_EMAIL = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangersByUnverifiedEmail(
      filter: $filter
      page: $page
      first: $first
      orderBy: $orderBy
    ) {
      id
      total_reservation
      last_booking_date
      last_signin_date
      users {
        id
        email
        roles {
          id
          name
        }
      }
      users {
        id
        email
        roles {
          id
          name
        }
      }
      company {
        id
        name
        arrangers {
          id
          company {
            id
            name
            class_type {
              id
              name
            }
          }
        }
      }
      prefix
      first_name
      last_name
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
      }
      nationality {
        id
        name
      }
      dob
      passport
      passport_exp
      address
      township {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
            country {
              id
              name
              iso_code_2
              iso_code_3
              currency_code
              nationality
              phone
            }
          }
        }
      }
    }
  }
`;

const GET_ARRANGER_MMCLOUD_LOG = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    yeastarCallReport(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        time
        call_type
        call_from_number
        call_from_name
        call_to_number
        call_to_name
        call_duration
        ring_duration
        talk_duration
        created_at
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_ARRANGER_RESERVATIONS = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    reservations(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        status {
          id
          name
        }
        eticket_send
        reserve_email_send
        eticket_sms_send
        reserve_sms_send
        products {
          id
          description
        }
        total {
          id
          show_fare_amount
          show_fare_currency
        }
        employee {
          id
          email
        }
        sales_channel {
          id
          name
        }
        created_by {
          id
          name
          email
        }
        created_at
      }
      paginatorInfo {
        count
        currentPage
        perPage
      }
    }
  }
`;

const CREATE_ARRANGER_COMMENT = gql`
  mutation ($input: commentInput) {
    createComment(input: $input) {
      id
      subject_id
      subject_type
      comments
      deleted_at
      created_at
      updated_at
      employee {
        id
        email
        name
        phone
      }
    }
  }
`;

const GET_ARRANGER_COMMENT = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    comments(page: $page, first: $first, filter: $filter, orderBy: $orderBy) {
      data {
        id
        subject_id
        subject_type
        comments
        deleted_at
        created_at
        updated_at
        employee {
          id
          name
          email
          phone
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const VERIFY_ARRANGER_EMAIL = gql`
  mutation ($input: VerifyEmailCCMInput!) {
    verifyArrangerEmailCCM(input: $input) {
      id
      email
      is_primary
      is_verified
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const UNVERIFY_ARRANGER_EMAIL = gql`
  mutation ($input: VerifyEmailCCMInput!) {
    unverifyArrangerEmailCCM(input: $input) {
      id
      email
      is_primary
      is_verified
      verified_by
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const VERIFY_ARRANGER_PHONE = gql`
  mutation ($input: VerifyArrangerPhoneCCMInput) {
    verifyArrangerPhoneCCM(input: $input) {
      id
      phone
      is_primary
      is_verified
      verified_by
      arranger {
        id
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
    }
  }
`;

const UNVERIFY_ARRANGER_PHONE = gql`
  mutation ($input: VerifyArrangerPhoneCCMInput) {
    unverifyArrangerPhoneCCM(input: $input) {
      id
      phone
      is_primary
      is_verified
      verified_by
      arranger {
        id
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
    }
  }
`;

const GET_ARRANGER_BY_ID = gql`
  query ($id: ID!) {
    arranger(id: $id) {
      id
      company {
        id
        name
      }
      prefix
      first_name
      last_name
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
      }
    }
  }
`;

export {
  CREATE_ARRANGER,
  CREATE_ARRANGER_TRAVELLER,
  ARRANGER_LOGIN,
  CREATE_TRAVELLER,
  ADD_ARRANGER_TRAVELLER,
  ADD_ARRANGER_COMPANY,
  ADD_ARRANGER_LOGIN,
  CHECK_ARRANGER_EMAIL,
  CHECK_ARRANGER_PHONE,
  GET_LOGIN_TYPE,
  CREATE_ARRANGER_PHONE,
  CREATE_ARRANGER_EMAIL,
  EDIT_ARRANGER_EMAIL,
  DELETE_ARRANGER_PHONE,
  DELETE_ARRANGER_EMAIL,
  ARRANGER_FILTER,
  MAKE_PRIMARY_ARRANGER_PHONE,
  MAKE_PRIMARY_ARRANGER_EMAIL,
  GET_ARRANGERS_BY_UNVERIFY_PHONE,
  GET_ARRANGERS_BY_UNVERIFY_EMAIL,
  GET_ARRANGER_MMCLOUD_LOG,
  GET_ARRANGER_RESERVATIONS,
  CREATE_ARRANGER_COMMENT,
  GET_ARRANGER_COMMENT,
  VERIFY_ARRANGER_EMAIL,
  UNVERIFY_ARRANGER_EMAIL,
  VERIFY_ARRANGER_PHONE,
  UNVERIFY_ARRANGER_PHONE,
  GET_ARRANGER_BY_ID,
};
