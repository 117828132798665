import { useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { isAuthenticated } from "../lib/auth";
import { LOGIN_PEOPLE_DETAIL } from "../config/constants";
import { getClientTag, getutmId, getCampaignId } from "../utils/checkurl";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_SESSION,
  CREATE_USER_ACTION,
} from "../queries/flymyasessionquery";
import moment from "moment";
import { getCookie } from "../utils/getcookies";
import { isJson } from "../utils/CheckIsJson";
import {
  openDB,
  addItem,
  getItems,
  //updateItem,
  //deleteItem,
  //clearObjectStore,
  //deleteDatabase,
} from "../lib/indexedDB";

const localStorageName = "guest_flymya_session";
const deviceIdsName = "device_ids";

function FlymyaSessionHook() {
  const [uriValue] = useState(window.location.href);
  const [utmId] = useState(getutmId(window.location.search));
  const [campaignId] = useState(getCampaignId(window.location.search));

  const expireTimeout = Date.now() + 24 * 60 * 60 * 1000; // 24 hours
  //const expireTimeout = Date.now() + 10 * 1000; // 10 sec
  //const expireTimeout = Date.now() + 10 * 60 * 1000; // 10 mins

  const oldLocalStorageSession = localStorage.getItem("guest-fly-session");
  if (oldLocalStorageSession) {
    localStorage.removeItem("guest-fly-session");
  }

  const getCurrentTimeInUTC630 = () => {
    return moment().utcOffset(390).format("YYYY-MM-DD HH:mm:ss");
  };

  const checkLocalStorageSupport = () => {
    let localstorageSupport;
    try {
      if (typeof localStorage !== "undefined") {
        // Test writing to localStorage
        localStorage.setItem("test", "testValue");
        // Test reading from localStorage
        const value = localStorage.getItem("test");
        // Test removing from localStorage
        localStorage.removeItem("test");

        localstorageSupport =
          value === "testValue"
            ? "localStorage is working"
            : "localStorage is not working";
      } else {
        throw new Error("localStorage is not supported");
      }
    } catch (e) {
      localstorageSupport = "localStorage is not supported";
    }
    return localstorageSupport;
  };

  const checkIndexedDBSuppport = () => {
    return new Promise((resolve) => {
      if (!window.indexedDB) {
        resolve("IndexedDB is not supported");
        return;
      }

      // Try to open a test database
      const request = indexedDB.open("testDatabase");

      request.onsuccess = (event) => {
        const db = event.target.result;
        db.close();
        indexedDB.deleteDatabase("testDatabase");
        resolve("IndexedDB is working");
      };

      request.onerror = (event) => {
        resolve("IndexedDB is not working");
      };
    });
  };

  const handleStorageCheck = async () => {
    const localStorageResult = checkLocalStorageSupport();
    const indexedDBResult = await checkIndexedDBSuppport();
    return {
      localstorage_support: localStorageResult,
      indexeddb_support: indexedDBResult,
    };
  };

  const updateLocalStorageSession = (id) => {
    const guestSession = localStorage.getItem(localStorageName);
    const { sessionId, expirationTime } = JSON.parse(guestSession);
    const arrangerData = localStorage.getItem(LOGIN_PEOPLE_DETAIL);
    const arranger =
      arrangerData && arrangerData !== "" ? JSON.parse(arrangerData) : null;
    if (guestSession) {
      localStorage.setItem(
        localStorageName,
        JSON.stringify({
          sessionId,
          expirationTime,
          id: id,
          arrangerId:
            isAuthenticated() && arranger && arranger.id ? arranger.id : null,
          updatedAt: getCurrentTimeInUTC630(),
        }),
      );
    }
  };

  const checkDeviceIdAndRegenerate = async () => {
    const localDeviceUuId = localStorage.getItem("device_id_uuidv4");
    const hasDeviceId =
      localDeviceUuId &&
      (localDeviceUuId !== "" ||
        localDeviceUuId !== null ||
        localDeviceUuId !== "null" ||
        localDeviceUuId !== undefined ||
        localDeviceUuId !== "undefined")
        ? true
        : false;

    //--- if device_id_uuidv4 not exits, regenerate device id and add to local storage  ----
    if (!hasDeviceId) {
      await setDeviceIdsToLocalStorage();
    }
  };

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted: (res) => {
      updateLocalStorageSession(res?.createFlymyaSession?.id);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(CREATE_SESSION, {
    onCompleted: (res) => {
      updateLocalStorageSession(res?.createFlymyaSession?.id);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const generateSessionId = () => {
    return uuidv4();
  };

  const generateDeviceId = () => {
    const timestamp = Date.now().toString(36);
    const randomStr = Math.random().toString(36).substr(2, 6);
    return timestamp + randomStr;
  };

  const generateSecureDeviceId = useCallback(() => {
    const crypto = window.crypto || window.msCrypto;
    if (crypto && crypto.getRandomValues) {
      const array = new Uint32Array(2);
      crypto.getRandomValues(array);
      return array[0].toString(36) + array[1].toString(36);
    } else {
      return generateDeviceId();
    }
  }, []);

  const setDeviceIdsToLocalStorage = useCallback(
    async (cusEmail, cusPhone) => {
      const deviceIds = localStorage.getItem(deviceIdsName);

      const cookieData = Cookies.get("AMP_9bdc728a74");
      const cookie =
        cookieData && cookieData !== "" && isJson(cookieData)
          ? JSON.parse(cookieData)
          : null;
      const cookieDataTwo = getCookie("AMP_9bdc728a74");
      const cookieTwo =
        cookieDataTwo && cookieDataTwo !== "" && isJson(cookieDataTwo)
          ? JSON.parse(cookieDataTwo)
          : null;

      const emailVal = localStorage.getItem("identifier");
      const loginDetail = localStorage.getItem("login_people_detail");
      const loginDetailData =
        loginDetail && isJson(loginDetail) ? JSON.parse(loginDetail) : null;

      const emailSessionData =
        emailVal && emailVal !== ""
          ? emailVal
          : loginDetailData
          ? loginDetailData.arranger_emails &&
            loginDetailData.arranger_emails.length > 0 &&
            loginDetailData.arranger_emails.find((val) => val.is_primary)
            ? loginDetailData.arranger_emails.find((val) => val.is_primary)
                .email
            : cusEmail
            ? cusEmail
            : "-"
          : cusEmail
          ? cusEmail
          : "-";

      const phoneSessionData = loginDetailData
        ? loginDetailData.arranger_phones &&
          loginDetailData.arranger_phones.length > 0 &&
          loginDetailData.arranger_phones.find((val) => val.is_primary)
          ? `${
              loginDetailData.arranger_phones.find((val) => val.is_primary)
                .phone_prefix
            } ${
              loginDetailData.arranger_phones.find((val) => val.is_primary)
                .phone
            }`
          : cusPhone
          ? cusPhone
          : "-"
        : cusPhone
        ? cusPhone
        : "-";

      const existingUUID = localStorage.getItem("device_id_uuidv4");
      // const newUUID = `${generateSecureDeviceId()}-${generateSessionId()}`;

      let newUUID = "";
      const sessionId = generateSessionId();
      const secureDeviceId = generateSecureDeviceId();
      if (sessionId && secureDeviceId) {
        newUUID = `${secureDeviceId}-${sessionId}`;
      } else {
        newUUID = sessionId ? sessionId : secureDeviceId;
      }

      const allItems = await getItems();
      if (allItems.length === 0) {
        await addItem({
          device_id_uuidv4:
            existingUUID &&
            (existingUUID !== "" ||
              existingUUID !== null ||
              existingUUID !== "null" ||
              existingUUID !== undefined ||
              existingUUID !== "undefined")
              ? existingUUID
              : newUUID,
        });
      }

      if (!deviceIds || deviceIds === "" || deviceIds === undefined) {
        localStorage.setItem(
          deviceIdsName,
          JSON.stringify({
            device_id_react:
              cookie && cookie.deviceId
                ? cookie.deviceId
                : cookieTwo && cookieTwo.deviceId
                ? cookieTwo.deviceId
                : "-",
            device_id_uuidv4:
              existingUUID &&
              (existingUUID !== "" ||
                existingUUID !== null ||
                existingUUID !== "null" ||
                existingUUID !== undefined ||
                existingUUID !== "undefined")
                ? existingUUID
                : newUUID,
            device_id_email: emailSessionData,
            device_id_full_phone: phoneSessionData,
          }),
        );
        localStorage.setItem(
          "device_id_react",
          cookie && cookie.deviceId
            ? cookie.deviceId
            : cookieTwo && cookieTwo.deviceId
            ? cookieTwo.deviceId
            : "-",
        );
        localStorage.setItem(
          "device_id_uuidv4",
          existingUUID &&
            (existingUUID !== "" ||
              existingUUID !== null ||
              existingUUID !== "null" ||
              existingUUID !== undefined ||
              existingUUID !== "undefined")
            ? existingUUID
            : newUUID,
        );
        localStorage.setItem("device_id_email", emailSessionData);
        localStorage.setItem("device_id_full_phone", phoneSessionData);

        return;
      }

      const deviceIdsData =
        deviceIds && isJson(deviceIds) ? JSON.parse(deviceIds) : null;

      localStorage.setItem(
        deviceIdsName,
        JSON.stringify({
          device_id_react:
            cookie && cookie.deviceId
              ? cookie.deviceId
              : cookieTwo && cookieTwo.deviceId
              ? cookieTwo.deviceId
              : "-",
          device_id_uuidv4: deviceIdsData.device_id_uuidv4,
          device_id_email:
            deviceIdsData.device_id_email !== "-"
              ? deviceIdsData.device_id_email
              : emailSessionData,
          device_id_full_phone:
            deviceIdsData.device_id_full_phone !== "-"
              ? deviceIdsData.device_id_full_phone
              : phoneSessionData,
        }),
      );
      localStorage.setItem(
        "device_id_react",
        cookie && cookie.deviceId
          ? cookie.deviceId
          : cookieTwo && cookieTwo.deviceId
          ? cookieTwo.deviceId
          : "-",
      );
      localStorage.setItem("device_id_uuidv4", deviceIdsData.device_id_uuidv4);
      localStorage.setItem(
        "device_id_email",
        deviceIdsData.device_id_email !== "-"
          ? deviceIdsData.device_id_email
          : emailSessionData,
      );
      localStorage.setItem(
        "device_id_full_phone",
        deviceIdsData.device_id_full_phone !== "-"
          ? deviceIdsData.device_id_full_phone
          : phoneSessionData,
      );
    },
    [generateSecureDeviceId],
  );

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
  };

  const createNewSession = useCallback(async () => {
    await checkDeviceIdAndRegenerate();
    const sessionId = generateSessionId();
    const arrangerData = localStorage.getItem(LOGIN_PEOPLE_DETAIL);
    const arranger =
      arrangerData && arrangerData !== "" && isJson(arrangerData)
        ? JSON.parse(arrangerData)
        : null;

    const clientTag = getClientTag(window.location.search);

    localStorage.setItem(
      localStorageName,
      JSON.stringify({
        sessionId,
        expirationTime: expireTimeout,
        arrangerId:
          isAuthenticated() && arranger && arranger.id ? arranger.id : null,
      }),
    );

    //const deviceIds = localStorage.getItem(deviceIdsName);
    const deviceUUID = localStorage.getItem("device_id_uuidv4");
    // const deviceIdsData =
    //   deviceIds && deviceIds !== "" && isJson(deviceIds)
    //     ? JSON.parse(deviceIds)
    //     : null;
    const allItems = await getItems();
    const deviceVal =
      allItems && allItems?.length > 0 && allItems?.[0]?.device_id_uuidv4
        ? allItems?.[0]?.device_id_uuidv4
        : deviceUUID;

    const { localstorage_support, indexeddb_support } =
      await handleStorageCheck();

    createSession({
      variables: {
        input: {
          session_id: sessionId,
          arranger_id: isAuthenticated()
            ? arranger && arranger.id
              ? arranger.id
              : null
            : 0,
          device_id:
            (isIOS() || isAndroid()) && deviceVal ? deviceVal : deviceUUID,
          client_platform_id:
            clientTag.replace(/\s/g, "") === "" ||
            clientTag === null ||
            clientTag === "null" ||
            clientTag === " " ||
            clientTag === undefined ||
            !clientTag
              ? "default"
              : clientTag,
          metadata: navigator.userAgent,
          localstorage_support: localstorage_support,
          indexeddb_support: indexeddb_support,
        },
      },
    });
  }, [createSession, expireTimeout]);

  const isGuestSessionExpired = () => {
    const guestSession = localStorage.getItem(localStorageName);

    if (guestSession) {
      const { updatedAt } = JSON.parse(guestSession);
      const updatedAtMoment = moment(
        updatedAt,
        "YYYY-MM-DD HH:mm:ss",
      ).utcOffset(390);
      const now = moment().utcOffset(390);
      const differenceInMinutes = now.diff(updatedAtMoment, "minutes");
      const isSessionExpired = differenceInMinutes > 30;
      return isSessionExpired;
    }

    // if (guestSession) {
    //   const { expirationTime } = JSON.parse(guestSession);
    //   return expirationTime < Date.now();
    // }

    return false;
  };

  const handleInitDB = () => {
    const initDB = async () => {
      await openDB();
      //const allItems = await getItems();
      //console.log("all", allItems);

      // if (allItems.length === 0) {
      //   handleAddUUIDToDB();
      // }
    };

    initDB();
  };

  // const handleAddUUIDToDB = async () => {
  //   await addItem({
  //     device_id_uuidv4: `${generateSecureDeviceId()}-${generateSessionId()}`,
  //   });
  // };

  // const handleUpdateItem = async (id, newVal) => {
  //   await updateItem({ id, name: newVal });
  // };

  // const handleDeleteItem = async (id) => {
  //   await deleteItem(id);
  // };

  const handleFlymyaSession = () => {
    localStorage.removeItem("guestSession");
    localStorage.removeItem("guest-session");

    handleInitDB();
    setDeviceIdsToLocalStorage();

    const guestSession = localStorage.getItem(localStorageName);

    if (!guestSession) {
      createNewSession();
      return;
    }

    const { id, sessionId, expirationTime, arrangerId, updatedAt } =
      JSON.parse(guestSession);
    const arrangerData = localStorage.getItem(LOGIN_PEOPLE_DETAIL);
    const arranger =
      arrangerData && arrangerData !== "" ? JSON.parse(arrangerData) : null;

    // if (
    //   guestSession &&
    //   !isGuestSessionExpired() &&
    //   (arrangerId === null || arrangerId === "null")
    // ) {
    //   createSession({
    //     variables: {
    //       input: {
    //         id: Number(id),
    //         session_id: sessionId,
    //         arranger_id: isAuthenticated()
    //           ? arranger && arranger.id
    //             ? arranger.id
    //             : null
    //           : 0,
    //       },
    //     },
    //   });
    //   return;
    // }

    if (guestSession && isGuestSessionExpired()) {
      const updatedAtMoment = moment(
        updatedAt,
        "YYYY-MM-DD HH:mm:ss",
      ).utcOffset(390);
      const newUpdatedAtMoment = updatedAtMoment.add(30, "minutes");
      //update the session with expired_at = updated_at+30 min
      updateSession({
        variables: {
          input: {
            id: Number(id),
            session_id: sessionId,
            // expired_at: moment(expirationTime).format("YYYY-MM-DD HH:mm:ss"),
            expired_at: newUpdatedAtMoment.format("YYYY-MM-DD HH:mm:ss"),
          },
        },
      });
      createNewSession();
    }
  };

  const [createUserAction] = useMutation(CREATE_USER_ACTION, {
    onCompleted: async (res) => {
      localStorage.setItem("userActionId", res?.createUserAction?.id);

      //--- update session every time a userAction is created ----
      const guestSession = localStorage.getItem(localStorageName);
      const { id, sessionId } = JSON.parse(guestSession);
      const arrangerData = localStorage.getItem(LOGIN_PEOPLE_DETAIL);
      const arranger =
        arrangerData && arrangerData !== "" ? JSON.parse(arrangerData) : null;

      const deviceId = localStorage.getItem("device_id_uuidv4");

      if (guestSession) {
        updateSession({
          variables: {
            input: {
              id: Number(id),
              session_id: sessionId,
              arranger_id: isAuthenticated()
                ? arranger && arranger.id
                  ? arranger.id
                  : null
                : 0,
              device_id: deviceId,
            },
          },
        });
      }
      //-----
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleCreateUserAction = ({ eventName, serializedPayload }) => {
    const guestSession = localStorage.getItem(localStorageName);
    const arrangerData = localStorage.getItem(LOGIN_PEOPLE_DETAIL);
    const arranger =
      arrangerData && arrangerData !== "" && isJson(arrangerData)
        ? JSON.parse(arrangerData)
        : null;

    if (guestSession) {
      const { sessionId } = JSON.parse(guestSession);
      checkDeviceIdAndRegenerate();

      createUserAction({
        variables: {
          input: {
            flymya_event: eventName,
            flymya_session: sessionId,
            utm_id: utmId !== "" ? Number(utmId) : null,
            campaign: campaignId !== "" ? campaignId : null,
            uri: uriValue,
            serialized_payload: serializedPayload ? serializedPayload : null,
            arranger_id: isAuthenticated()
              ? arranger && arranger.id
                ? arranger.id
                : null
              : 0,
          },
        },
      });
    }
  };

  const handleUpdateArrangerId = async (arrangerIdVal) => {
    await checkDeviceIdAndRegenerate();
    const guestSession = localStorage.getItem(localStorageName);
    const { localstorage_support, indexeddb_support } =
      await handleStorageCheck();
    if (guestSession) {
      const { id, sessionId, arrangerId } = JSON.parse(guestSession);
      if (
        guestSession &&
        !isGuestSessionExpired() &&
        (arrangerId === null || arrangerId === "null" || arrangerId === "")
      ) {
        createSession({
          variables: {
            input: {
              id: Number(id),
              session_id: sessionId,
              arranger_id: arrangerIdVal ? arrangerIdVal : null,
              localstorage_support: localstorage_support,
              indexeddb_support: indexeddb_support,
            },
          },
        });
      }
    }
  };

  return {
    createNewSession,
    handleFlymyaSession,
    handleCreateUserAction,
    setDeviceIdsToLocalStorage,
    handleUpdateArrangerId,
  };
}

export default FlymyaSessionHook;
