import React, { useEffect, useState } from "react";
import { Paper, Button, IconButton } from "@material-ui/core";
import { StarRounded } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withRouter } from "../../utils/withRouter";
import { calculateNights } from "../../utils/date";
import MediaQuery from "react-responsive";
import { IS_CALL_CENTER, IS_B2B } from "../../config/constants";
import { useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { IMG_CDN_URL } from "../../config/constants";

const CheckMark = `${IMG_CDN_URL}/check-mark.png`;

const HotelLongItem = ({
  hotel,
  match,
  selectedLanguage,
  currency,
  nationality,
  isInApp,
  isInWeb,
  classType,
}) => {
  const { city, checkIn, checkOut, adultChildRoom, lat, long } = useParams();
  const room = hotel?.rooms && hotel?.rooms[0];
  const nights = calculateNights(checkIn, checkOut);
  const stars = hotel?.stars ? hotel?.stars : 0;
  const adultCount = adultChildRoom?.split("-")[0];
  const hotelPhoto =
    hotel.hotel_photo && hotel.hotel_photo && hotel.hotel_photo[0]
      ? hotel.hotel_photo[0]
      : null;
  const isB2B =
    localStorage.getItem(IS_B2B) && localStorage.getItem(IS_B2B) === "yes"
      ? true
      : false;
  const isCallCenter =
    localStorage.getItem(IS_CALL_CENTER) &&
    localStorage.getItem(IS_CALL_CENTER) === "yes"
      ? true
      : false;

  const [seeDetailService, setSeeDetailService] = useState(false);

  const service = hotel?.services?.split("\n-").map((part) => part.trim());
  const { t } = useTranslation();

  return (
    <>
      <MediaQuery minWidth={781}>
        {/* alternate ribbon design */}
        {/* {hotel.is_custom_price && (
          <div className="corner-ribbon-wrapper d-flex">
            <div className="corner-ribbon">
              <span className="corner-ribbon__content">
                <Star className="corner-ribbon-star" elevation={1} />
              </span>
            </div>
          </div>
        )} */}
        {/* alternate ribbon design */}
      </MediaQuery>
      <Paper className="HotelLongItem align-items-stretch">
        <MediaQuery minWidth={781}>
          <div className="d-flex align-items-stretch flex-col">
            <div className="d-flex align-items-stretch flex-row">
              {hotelPhoto && hotelPhoto?.url_max300 ? (
                <div
                  className="hotel-img"
                  style={{ backgroundImage: `url(${hotelPhoto?.url_max300})` }}
                />
              ) : (
                <div className="hotel-img no-img" />
              )}

              <div className="hotel-contents flex-grow d-flex flex-col">
                <div className="flex-grow">
                  <div className="d-flex align-items-center hotel-detail">
                    <>
                      <h4 className="fg-blue hotel-item-title">
                        {hotel?.hotel_name}
                      </h4>

                      {hotel?.stars &&
                        [...Array(Number(hotel.stars))].map((s, i) => (
                          <StarRounded key={i} className="h-icon yellow-icon" />
                        ))}
                    </>

                    {/* {hotel.preferred && (
                  <ThumbUpRounded
                    style={{ marginLeft: "8px" }}
                    className="h-icon blue-icon"
                  />
                )} */}
                  </div>

                  <small className="hotel-item-address">{hotel?.address}</small>

                  <small className="hotel-item-duration">
                    Minimum Length of Stay - {hotel?.min_length_of_stay}
                  </small>
                </div>

                <div
                  style={{ display: "block" }}
                  //className="d-flex justify-content-end align-items-end"
                >
                  <div
                    className="hotel-long-price"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        // flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "center",
                        width: "80%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            textDecorationLine: "underline",
                            color: "#00aeef",
                            fontSize: 14,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSeeDetailService(!seeDetailService);
                          }}
                        >
                          {t("hotel.see_hotel_service") !==
                          "hotel.see_hotel_service"
                            ? t("hotel.see_hotel_service")
                            : "See inclusive services"}
                          <IconButton style={{ padding: 0 }}>
                            <ArrowDropDownIcon
                              style={{ color: "00aeef" }}
                              onClick={() => {}}
                            />
                          </IconButton>
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      {hotel?.show_price ? (
                        <div
                          style={{
                            marginBottom: 5,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <span className="hotel-item-price-label">
                            Starting From
                          </span>
                          <span className="hotel-item-price">
                            {`$ ` + hotel?.price}
                          </span>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                          }}
                        >
                          <img
                            src={CheckMark}
                            alt="Icon"
                            style={{ width: 20, height: 20, marginRight: 8 }}
                          ></img>
                          <span
                            className="hotel-item-price-label"
                            style={{ color: "#00A651", fontSize: 14 }}
                          >
                            Best Price Guaranteed
                          </span>
                        </div>
                      )}
                      <Link
                        to={{
                          pathname: `/${selectedLanguage}${
                            isInApp ? "/in-app" : ""
                          }/hotel-long-stay/${hotel?.hotel_id}/${
                            hotel?.show_price
                          }`,
                          search: window.location.search,
                        }}
                      >
                        <Button
                          className="hotel-btn-online"
                          style={{ fontSize: 14 }}
                        >
                          {t("hotel.room_pricing") !== "hotel.room_pricing"
                            ? t("hotel.room_pricing")
                            : "Check out rooms and pricing"}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
            {seeDetailService && (
              <div
                className="hotel-long-service-detail"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="hotel-long-service-room"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <h4>
                    {t("hotel.room_service") !== "hotel.room_service"
                      ? t("hotel.room_service")
                      : "Room's Service & Facilities"}
                  </h4>
                  <span style={{ fontSize: 14, paddingRight: 5 }}>
                    {ReactHtmlParser(hotel?.room_facilities)}
                  </span>
                </div>
                <div
                  className="hotel-long-service-other"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <h4>
                    {t("hotel.property_service") !== "hotel.property_service"
                      ? t("hotel.property_service")
                      : "Property’s Service & Facilities"}
                  </h4>
                  <span style={{ fontSize: 14, paddingRight: 5 }}>
                    {ReactHtmlParser(hotel?.property_facilities)}
                  </span>
                </div>
              </div>
            )}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={780}>
          <div>
            <div
              className={
                hotelPhoto && hotelPhoto.url_original
                  ? "hotel-img"
                  : "hotel-img no-img"
              }
              style={
                hotelPhoto && hotelPhoto.url_original
                  ? {
                      backgroundImage: `url(${hotelPhoto?.url_original})`,
                    }
                  : {}
              }
            >
              <div className="hotel-item-title">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h4>{hotel?.hotel_name}</h4>
                  {/* <div className="hotel-stars"> */}
                  {hotel?.stars &&
                    [...Array(Number(hotel.stars))].map((s, i) => (
                      <StarRounded key={i} className="h-icon yellow-icon" />
                    ))}
                  {/* </div> */}
                </div>

                <span>{hotel?.address}</span>
              </div>
            </div>

            <div className="justify-content-between">
              {/* <div className="d-flex flex-col" style={{ maxWidth: "57%" }}>
                <div className="hotel-stars">
                  <>
                    {hotel.stars &&
                      [...Array(Number(hotel.stars))].map((s, i) => (
                        <StarRounded key={i} className="h-icon yellow-icon" />
                      ))}
                  </>
                </div>
                <div className="room-info-text">
                  <LocationOnRounded />
                  <span>{hotel.address}</span>
                </div>
              </div> */}

              <div className="d-flex flex-col hotel-item-price">
                <div
                  //style={{ display: "block" }}
                  className=" justify-content-end align-items-end"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      float: "left",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                      width: "50%",
                    }}
                  >
                    <small
                      className="hotel-item-duration"
                      style={{ fontSize: 12 }}
                    >
                      Minimum Length of Stay - {hotel?.min_length_of_stay}
                    </small>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          textDecorationLine: "underline",
                          color: "#00aeef",
                          fontSize: 12,
                        }}
                        onClick={() => {
                          setSeeDetailService(!seeDetailService);
                        }}
                      >
                        {t("hotel.see_hotel_service") !==
                        "hotel.see_hotel_service"
                          ? t("hotel.see_hotel_service")
                          : "See inclusive services"}
                        <IconButton style={{ padding: 0 }}>
                          <ArrowDropDownIcon
                            style={{ color: "00aeef" }}
                            onClick={() => {}}
                          />
                        </IconButton>
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    {hotel?.show_price ? (
                      <>
                        <span
                          className="hotel-item-price-label"
                          style={{ fontSize: 12 }}
                        >
                          Starting From
                        </span>
                        <span className="hotel-item-price">
                          {`$ ` + hotel?.price}
                        </span>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 5,
                        }}
                      >
                        <img
                          src={CheckMark}
                          alt="Icon"
                          style={{ width: 20, height: 20 }}
                        ></img>
                        <span
                          className="hotel-item-price-label"
                          style={{
                            color: "#00A651",
                            fontSize: 12,
                          }}
                        >
                          Best Price Guaranteed
                        </span>
                      </div>
                    )}
                    <Link
                      to={{
                        pathname: `/${selectedLanguage}${
                          isInApp ? "/in-app" : ""
                        }/hotel-long-stay/${hotel?.hotel_id}/${
                          hotel?.show_price
                        }`,
                        search: window.location.search,
                      }}
                    >
                      <Button
                        className="hotel-btn-online"
                        style={{ fontSize: 14 }}
                      >
                        {t("hotel.room_pricing") !== "hotel.room_pricing"
                          ? t("hotel.room_pricing")
                          : "Check out rooms and pricing"}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              {seeDetailService && (
                <div
                  className="hotel-long-service-detail"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="hotel-long-service-room"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ fontSize: 14 }}>
                      {t("hotel.room_service") !== "hotel.room_service"
                        ? t("hotel.room_service")
                        : "Room's Service & Facilities"}
                    </h4>
                    <span style={{ fontSize: 12, paddingRight: 5 }}>
                      {ReactHtmlParser(hotel?.room_facilities)}
                    </span>
                  </div>
                  <div
                    className="hotel-long-service-other"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ fontSize: 14 }}>
                      {t("hotel.property_service") !== "hotel.property_service"
                        ? t("hotel.property_service")
                        : "Property’s Service & Facilities"}
                    </h4>
                    <span style={{ fontSize: 12, paddingRight: 5 }}>
                      {ReactHtmlParser(hotel?.property_facilities)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </MediaQuery>
      </Paper>
    </>
  );
};

export default withRouter(HotelLongItem);
