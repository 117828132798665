export const IMG_BASE_URL = "https://flymya.com";
export const DATALAYER_URL = "https://booking.flymya.com";
export const IMG_CDN_URL = "https://cdn.flymya.com/assets/images";
export const REACT_APP_GORGIAS_TICKET =
  "https://v4-be.flymya.com/api/gorgias/post/tickets/";
export const REACT_APP_REDIRECT_URL = window.location.origin;

export const CALL_CENTER_PHONENO = "+95 9 880 441 011";
export const CC_PHONENO_LINK = "tel:+959880441011";

export const MM_LOCALE = "my-MM";
export const EN_LOCALE = "en-US";
export const MY_MM = "my-mm";

export const MY_LANGUAGE_ID = 4;
export const EN_LANGUAGE_ID = 1;

export const BUS_TIMEOUT = 7;

export const TWO_MINS_TIMEOUT = 120000;
export const TEN_MINS_TIMEOUT = 600000;

export const TOP_CITY_BUS = [
  "Yangon",
  "Mandalay",
  "Naypyitaw (Myoma)",
  "Naypyitaw (Bawga)",
  "Naypyitaw (Thapyaygone)",
  "Naypyitaw(Toll Gate)",
];

export const TOKEN_TYPE = "token_type";
export const AUTH_TOKEN = "access_token";
export const LOGIN_USER_ID = "userId";
export const IDENTIFIER = "identifier";
export const EXPIRES_IN = "expires_in";
export const LOGIN_PEOPLE_COMPANY = "login_company";
export const LOGIN_PEOPLE_CLASS_TYPE = "login_company_class_type";
export const LOGIN_USER_PEOPLE = "login_people";
export const IS_B2B = "is_b2b";
export const IS_CALL_CENTER = "is_call_center";
export const IS_CUSTOMER = "is_customer";
export const IS_EMPLOYEE = "is_employee";
export const LOGIN_PEOPLE_DETAIL = "login_people_detail";
export const B2B_ClASS_TYPE = "b2bClassType";
export const LOGIN_NAME = "login_name";
export const LOGIN_EMAIL = "login_email";
export const LOGIN_PHONE_PREFIX = "login_phone_prefix";
export const LOGIN_PHONE = "login_phone";
export const LOGIN_SOCIAL = "login_social";
export const LOGIN_TYPE = "login_type";
export const SOCIAL_TOKEN = "social_token";
export const ADDRESSBOOK_ID = "addressbook_id";
export const ARRANGER_PEOPLE_ID = "arranger_people_id";
export const PASSENGER_COUNT = "passenger_count";
export const LOGIN_VIA_KBZPAY = "kbz_user_data";

export const B2BCLASSTYPE_B_ID = "1";
export const B2BCLASSTYPE_N_ID = "2";
export const B2C_ID = "3";
export const B2BCLASSTYPE_C_ID = "4";
export const GUEST_ID = "5";
export const B2BCLASSTYPE_E_ID = "6";
export const B2BCLASSTYPE_S_ID = "7";
export const B2BCLASSTYPE_B_ID_NAME = "Class B";
export const B2BCLASSTYPE_N_ID_NAME = "Class N";
export const B2C_ID_NAME = "B2C";
export const B2BCLASSTYPE_C_ID_NAME = "Class C";
export const B2BCLASSTYPE_E_NAME = "Class E";
export const B2BCLASSTYPE_S_NAME = "Class S";

export const PASSENGER_MAX_COUNT = 5;

export const DOMESTIC_PRODUCT_TYPE_ID = 1;
export const DOMESTIC_ROUNDTRIP_PRODUCT_TYPE_ID = 12;
export const HOTEL_PRODUCT_TYPE_ID = 4;
export const VISA_PRODUCT_TYPE_ID = 20;
export const AIRPORT_VIP_PRODUCT_TYPE_ID = 23;
export const INSURANCE_PRODUCT_TYPE_ID = 19;
export const BALLOON_PRODUCT_TYPE_ID = 5;
export const CARRENTAL_PRODUCT_TYPE_ID = 6;

export const SALES_WAVEPAY_APP = 19;
export const SALES_ONEPAY_APP = 18;
export const SALES_KBZPAY_APP = 17;
export const SALES_MPITESAN_APP = 22;
export const SALES_AYAPAY_APP = 44;
export const SALES_MOMONEY_APP = 49;
export const SALES_UAB_APP = 50;
export const SALES_WEBSITE = 15;
export const SALES_DESKTOP = 23;
export const SALES_MOBILE = 24;
export const SALES_TABLET = 25;
export const SALES_B2B_PORTAL = 28;
export const SALES_B2B_OFFLINE_PORTAL = 39;
export const SALES_NINJA_VAN_INWEB = 52;
export const SALES_UAB_INWEB = 53;
export const SALES_ABC_STORE_INWEB = 54;
export const SALES_KBZ_INWEB = 55;
export const SALES_VIBER_INWEB = 56;
export const SALES_CITY_REWARD_INWEB = 57;
export const SALES_B2C_OFFLINE_PROMO = 50; //need to align with live sale channel id before push
export const SALES_GRAB_INWEB = 61;
export const SALES_ATOM_INWEB = 62;
export const SALES_FOODPANDA_INWEB = 63;

export const B2B_OFFLINE_PORTAL = 40;
export const B2B_CORPORATE_ONLINE = 41;
export const B2B_CORPORATE_OFFLINE = 42;
export const SALES_CITIZENPAY_APP = 43;

export const SOURCE_TYPE_CAMPAIGN = 1;
export const SOURCE_TYPE_REFERRAL = 2;
export const SOURCE_TYPE_PROMO = 3;
export const SOURCE_TYPE_DEVICE = 4;
export const SOURCE_TYPE_PROMOTION = 11;
export const SOURCE_TYPE_WALLET_ACTIVITY = 10;
export const SOURCE_TYPE_CLIENT = 12;
export const SOURCE_TYPE_AUTO_DISCOUNT = 6;

export const FLYMYA_FAMILY_MMK = "1359_companyAccount";
export const FLYMYA_FAMILY_USD = "1360_companyAccount";
export const FLYMYA_COMPANY_EXPENSE_MMK = "1532_companyAccount";
export const FLYMYA_COMPANY_EXPENSE_USD = "1533_companyAccount";

export const ONLINE_PAYMENTS = [
  "KBZ Pay",
  "Wave Money",
  "Stripe",
  "MPU (2C2P)",
  "Paypal",
  "M-Pitesan",
  "123 OTC (2C2P)",
  "MoMoney",
];

export const PRODUCT_GROUPS = [
  { name: "flight", id: 1 },
  { name: "bus", id: 2 },
  { name: "balloon", id: 3 },
  { name: "car", id: 4 },
  { name: "services", id: 5 },
  { name: "tour", id: 6 },
  { name: "hotel", id: 7 },
  { name: "offline_hotel", id: 8 },
  { name: "online_hotel", id: 9 },
  { name: "long_stay_hotel", id: 10 },
];

export const getIataCodes = (city) => {
  const airportMapping = {
    "New York, USA": ["JFK", "LGA", "EWR"],
    "Los Angeles, USA": ["LAX", "BUR", "LGB", "ONT", "SNA"],
    "Chicago, USA": ["ORD", "MDW"],
    "San Francisco, USA": ["SFO", "OAK", "SJC"],
    "Washington, D.C., USA": ["IAD", "DCA", "BWI"],
    "London, UK": ["LHR", "LGW", "LCY", "LTN", "STN", "SEN"],
    "Berlin, Germany": ["BER"],
    "Frankfurt, Germany": ["FRA", "HHN"],
    "Paris, France": ["CDG", "ORY", "BVA"],
    "Tokyo, Japan": ["NRT", "HND"],
    "Osaka, Japan": ["KIX", "ITM"],
    "Toronto, Canada": ["YYZ", "YTZ"],
    "Shanghai, China": ["PVG", "SHA"],
    "Beijing, China": ["PEK", "PKX"],
    "Sydney, Australia": ["SYD"], // Future airport not included until operational
    "Bangkok, Thailand": ["BKK", "DMK"],
    "Moscow, Russia": ["SVO", "DME", "VKO", "ZIA"],
  };
  // return airportMapping[city] || [city.toUpperCase()];
  return airportMapping[city] || [];
};

export const MYANMAR_ID = 153;
export const YANGON_REGION = 4;
export const YANGON_CITY = 28;
export const SINGAPORE_ID = 200;

export const MOBILE_REDIRECT = "OFF"; // Value 'ON' || 'OFF' to show or hide the mobile redirect area (HomePromote Component)

export const TRAVEL_VISA_SERVICE = "visa-service";
export const TRAVEL_VIP_SERVICE = "vip-service";
export const TRAVEL_INSURANCE_SERVICE = "insurance-service";
export const HOTEL_LONG_STAY = "hotel-long-stay";

export const BALLOON_MAX_DATE = "2025-06-30";

export const autoCompleteMap = {
  // Correct spellings
  ind: "Indiana",
  rey: "Reykjavík",
  per: "Perth",
  seo: "Seoul",
  ams: "Amsterdam",
  lua: "Luang Phabang",
  kual: "Kuala Lumpur",
  vie: "Vienna",
  ban: "Bangkok",
  tok: "Tokyo",
  lon: "London",
  ahm: "Ahmedabad",
  yan: "Yangon",
  sie: "Siem Reap",
  kul: "Kulon Progo Regency",
  sin: "Singapore",
  che: "Chengdu",
  yer: "Yerevan",
  dub: "Dubai",
  mun: "Munich",
  lis: "Lisbon",
  mum: "Mumbai",
  mac: "Macau",
  los: "Los Angeles",
  sey: "Seychelles",
  doh: "Doha",
  chi: "Chicago",
  was: "Washington D.C.",
  hyd: "Hyderabad",
  xia: "Xiamen",
  dan: "Da Nang",
  chiM: "Chiang Mai",
  kol: "Kolkata",
  kus: "Kushiro",
  mir: "Miri",
  san: "San Francisco",
  vieL: "Vientiane",
  hon: "Hong Kong",
  maw: "Mawlamyine",
  osl: "Oslo",
  fuk: "Fukuoka",
  jin: "Jinan",
  sha: "Shanghai",
  bei: "Beijing",
  nak: "Nakhon Si Thammarat",
  lag: "Lagos",
  joh: "Johor Bahru",
  gay: "Gaya",
  kat: "Kathmandu",
  kho: "Khon Kaen Airport",
  nar: "Narita",
  nel: "Nelson",
  bir: "Birmingham",
  phi: "Phitsanulok",
  mal: "Malé",
  fuz: "Fuzhou",
  guj: "Gujarat",
  tia: "Tianjin",
  kao: "Kaohsiung City",
  lah: "Lahore",
  oki: "Okinawa",
  mos: "Moscow",
  udo: "Udon Thani",
  sea: "Seattle",
  sof: "Sofia",
  ist: "Istanbul",
  zav: "Zaventem",
  son: "Songkhla",
  isl: "Islamabad",
  lug: "Luganville",
  kom: "Komatsu",
  por: "Port Vila",
  bri: "Brisbane",
  nag: "Nagoya",
  nay: "Nay Pyi Taw",
  kaw: "Kawthaung",
  tac: "Tachilek",
  myi: "Myitkyina",
  bha: "Bhamo",
  ken: "Kengtung",
  las: "Lashio",
  put: "Putao",
  kal: "Kalemyo",
  mye: "Myeik (Mergui)",
  kya: "Kyaukpyu",
  daw: "Dawei",
  mon: "Monywa",
  hom: "Homalin",
  tha: "Thandwe",
  ann: "Ann",
  bok: "Bokpyin",
  kha: "Khamti",
  monH: "Monghsat",
  muh: "Muharraq Island",
  loi: "Loikaw",
  bas: "Bassein",
  mag: "Magway",
  manA: "Manaung",
  bal: "Bali",
  guaB: "Guangzhou Baiyun",
  gua: "Guangzhou",
  kun: "Kunming",
  manS: "Mangshi",
  pen: "Penang",
  lan: "Langkawi",
  auc: "Auckland",
  mil: "Milan",
  add: "Addis Ababa",
  jak: "Jakarta",
  tra: "Trang",
  sur: "Surabaya",
  man: "Mandalay",
  sit: "Sittwe",
  yiw: "Yiwu",
  hua: "Hua Hin",
  heh: "Heho",
  den: "Denver",
  phu: "Phuket",
  new: "New Delhi",
  pho: "Phnom Penh",
  bru: "Brunei",
  chiR: "Chiang Rai",
  jie: "Jieyang",
  nan: "Nanning",
  osa: "Osaka",
  zur: "Zurich",
  gra: "Grand Rapids Gerald R. Ford",
  lasV: "Las Vegas",
  qin: "Qingdao",
  sto: "Stockholm",
  nai: "Nairobi",
  min: "Minnesota",
  van: "Vancouver",
  syd: "Sydney",
  hou: "Houston",
  dha: "Dhaka",
  fra: "Frankfurt",
  mel: "Melbourne",
  han: "Hangzhou",
  par: "Paris",
  newY: "New York",
  nya: "Nyaung U",
  koa: "Ko Samui",
  bak: "Baku",
  kot: "Kota Kinabalu",
  koc: "Kochi",
  jai: "Jaipur",
  uda: "Udaipur",

  // Common misspellings or alternative spellings
  // bank: "Bangkok",
  // son: "Singapore",
  // yam: "Yangon",
  // wash: "Washington D.C.",
  // ny: "New York",
  // chiM: "Chiang Mai",
  // sing: "Singapore",
  // kul: "Kuala Lumpur",
  // manch: "Manchester",
  // tok: "Tokyo",
  // hk: "Hong Kong",
  // lond: "London",
  // isb: "Islamabad",
  // uae: "Dubai",
  // mos: "Moscow",
  // sydn: "Sydney",
  // par: "Paris",
  // fran: "Frankfurt",
  // van: "Vancouver",
  // melb: "Melbourne",
  // denv: "Denver",
  // safr: "San Francisco",
  // losa: "Los Angeles",
  // lasv: "Las Vegas",
};
