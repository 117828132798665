/* eslint-disable no-unused-expressions */
import React, { lazy, retry, useEffect, useState, useContext } from "react";
import { Grid, Modal, Button } from "@mui/material";
import { CALL_CENTER_PHONENO, CC_PHONENO_LINK } from "../../config/constants";
import { isMobile } from "react-device-detect";
import "../../assets/scss/OfflineIntMsgModal.scss";
import {
  B2BCLASSTYPE_B_ID,
  B2BCLASSTYPE_N_ID,
  B2BCLASSTYPE_C_ID,
} from "../../config/constants";
import { formatDateDayNameAndMonth } from "../../utils/date";
import { checkInWeb } from "../../utils/checkurl";
import FlymyaTravelInfoText from "../../components/FlymyaTravelnfoText";
import { GorgiasContext } from "../../GorgiasContext";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IMG_CDN_URL } from "../../config/constants";

const modalCloseIcon = `${IMG_CDN_URL}/modal-close.svg`;

// const Banner = lazy(() =>
//   retry(() => import("../../components/CovidAlertBanner")),
// );

const OfflineInternationalMsg = ({
  sendGorgiaMessage,
  searchInfo,
  productDetail,
  showContactUsModal,
  setShowContactUsModal,
  type,
  roundtripi,
  stateValue,
  isInApp,
  clientTag,
}) => {
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const { updateGorgiasState } = useContext(GorgiasContext);
  const { t } = useTranslation();

  let prefill_msg = "";
  if (type) {
    type === "flight" && roundtripi
      ? (prefill_msg = `Hi, I'm interested in ${
          productDetail?.[0]?.company
        } flight (${productDetail?.[0]?.external_id}) from ${
          productDetail?.[0]?.from
        } to ${productDetail?.[0]?.to} on ${moment(
          productDetail?.[0]?.departure_date,
          "YYYY-MM-DD",
        ).format("YYYY-MMM-DD")} and return flight ${
          productDetail?.[1]?.company
        } flight (${productDetail?.[1]?.external_id}) from ${
          productDetail?.[1]?.from
        } to ${productDetail?.[1]?.to} on ${moment(
          productDetail?.[1]?.departure_date,
          "YYYY-MM-DD",
        ).format("YYYY-MMM-DD")} . Can you help me?`)
      : type === "flight"
      ? (prefill_msg = `Hi, I'm interested in ${
          productDetail?.company
        } flight (${productDetail?.external_id}) from ${
          productDetail?.from
        } to ${productDetail?.to} on ${moment(
          productDetail?.departure_date,
          "YYYY-MM-DD",
        ).format("YYYY-MMM-DD")} with starting price of ${
          productDetail?.fareClasses[0].or
        } ${productDetail?.fareClasses[0].currency}. Can you help me?`)
      : type === "noflight"
      ? (prefill_msg =
          searchInfo?.endDate !== "0"
            ? `Hi, i'm interested in ${searchInfo.fromIata} - ${searchInfo.toIata} | ${searchInfo.startDate} -> ${searchInfo.endDate}|${searchInfo.tripType}. Can you help me?`
            : `Hi, i'm interested in ${searchInfo.fromIata} - ${searchInfo.toIata} |${searchInfo.startDate} |${searchInfo.tripType}. Can you help me?`)
      : type === "bus"
      ? (prefill_msg = `Hi, i'm interested in ${searchInfo?.operator_name_en} | ${searchInfo?.source_name_en} - ${searchInfo?.destination_name_en} | Departure Date/Time: ${searchInfo?.departure_date_time} | Arrival Date/Time: ${searchInfo?.est_arrival_date_time} | ${searchInfo?.fare_breakdowns?.gb} ${searchInfo?.currency}. Can you help me?`)
      : type === "hotel"
      ? (prefill_msg = searchInfo)
      : "";
  }

  const handleViewport = () => {
    if (window.innerWidth < 780) {
      updateGorgiasState(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="offlineIntMsgModal"
        open={showContactUsModal}
        id="reliefModal"
        onClose={(event, reason) => {
          window.GorgiasChat && window.GorgiasChat.close();
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            setShowContactUsModal(false);
          }
        }}
      >
        <Grid
          className="confirmationBox contactUsModal customerContainer"
          style={{
            // width: "420px",
            backgroundColor: "#fff",
            outline: "none",
            position: "absolute",
          }}
          container
        >
          {/* <div className="close-wrap">
            <img
              src={closeIcon}
              alt="close"
              onClick={() => setShowContactUsModal(false)}
              className="closeImg"
            />
          </div> */}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              className="closeModal"
              src={modalCloseIcon}
              alt="close"
              width="8%"
              height="8%"
              onClick={() => {
                setShowContactUsModal(false);
              }}
            />
          </div>
          {clientTag !== "kbz" && (
            <div className="btn-wrap">
              <Button
                className="btnChoose"
                onClick={() => {
                  setShowContactUsModal(false);
                  // let prefill_msg = "";
                  // if (productDetail) {
                  //   prefill_msg = `Hi, i'm interested in ${productDetail?.company} flight (${productDetail?.external_id}) from ${productDetail?.departure_time}to ${productDetail?.arrival_time} on ${productDetail?.departure_date} with starting price of ${productDetail?.fareClasses[0].or} ${productDetail?.fareClasses[0].currency}. Can you help me?`;
                  // }
                  // if (searchInfo) {
                  //   prefill_msg =
                  //     searchInfo?.endDate !== "0"
                  //       ? `Hi, i'm interested in ${searchInfo.fromIata} - ${searchInfo.toIata} | ${searchInfo.startDate} -> ${searchInfo.endDate}|${searchInfo.tripType}. Can you help me?`
                  //       : `Hi, i'm interested in ${searchInfo.fromIata} - ${searchInfo.toIata} |${searchInfo.startDate} |${searchInfo.tripType}. Can you help me?`;
                  // }

                  if (isMobile) {
                    window.open(
                      `http://m.me/flymyatravels?ref=myrefcode&text=${prefill_msg}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  } else {
                    window.open(
                      // `https://www.messenger.com/t/flymyatravels?ref=myrefcode&text=${prefill_msg}`,
                      `https://www.facebook.com/flymyatravels`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }
                }}
                style={{ width: "100%" }}
              >
                <svg
                  viewBox="0 0 70 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_7_120)">
                    <path
                      d="M35 0C15.2862 0 0 14.4463 0 33.95C0 44.1525 4.1825 52.9725 10.99 59.0625C11.5587 59.57 11.9088 60.2875 11.9263 61.0575L12.1187 67.2875C12.1328 67.7459 12.2592 68.1938 12.4868 68.5919C12.7145 68.99 13.0365 69.3261 13.4244 69.5707C13.8124 69.8152 14.2545 69.9607 14.7119 69.9944C15.1693 70.028 15.6279 69.9488 16.0475 69.7638L22.995 66.7013C23.5812 66.4388 24.2463 66.395 24.8675 66.5613C28.0613 67.4363 31.4562 67.9088 35 67.9088C54.7137 67.9088 70 53.4625 70 33.9588C70 14.455 54.7137 0 35 0Z"
                      fill="url(#paint0_radial_7_120)"
                    />
                    <path
                      d="M13.9825 43.8812L24.2638 27.5712C24.6505 26.9572 25.1604 26.43 25.7612 26.0229C26.362 25.6158 27.0406 25.3377 27.7543 25.2062C28.468 25.0746 29.2012 25.0925 29.9077 25.2586C30.6141 25.4247 31.2785 25.7354 31.8588 26.1712L40.04 32.305C40.4052 32.5784 40.8496 32.7255 41.3058 32.7239C41.7621 32.7223 42.2054 32.5722 42.5688 32.2962L53.6113 23.9137C55.0813 22.7937 57.0063 24.5612 56.0263 26.1275L45.7363 42.4287C45.3495 43.0428 44.8396 43.57 44.2388 43.9771C43.638 44.3841 42.9594 44.6622 42.2457 44.7938C41.532 44.9253 40.7988 44.9075 40.0923 44.7414C39.3859 44.5753 38.7215 44.2645 38.1413 43.8287L29.96 37.695C29.5948 37.4215 29.1504 37.2745 28.6942 37.2761C28.2379 37.2776 27.7946 37.4278 27.4313 37.7037L16.3888 46.0862C14.9188 47.2062 12.9938 45.4475 13.9825 43.8812Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <radialGradient
                      id="paint0_radial_7_120"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(11.725 70) scale(77 77)"
                    >
                      <stop stop-color="#0099FF" />
                      <stop offset="0.6" stop-color="#A033FF" />
                      <stop offset="0.9" stop-color="#FF5280" />
                      <stop offset="1" stop-color="#FF7061" />
                    </radialGradient>
                    <clipPath id="clip0_7_120">
                      <rect width="70" height="70" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div
                  style={{
                    display: "flex",
                  }}
                  className="messenger-chat"
                >
                  <div>
                    {t("masterHomeSecondPage.messenger_chat") !==
                    "masterHomeSecondPage.messenger_chat"
                      ? t("masterHomeSecondPage.messenger_chat")
                      : "Messenger"}
                  </div>
                  <div
                    style={{
                      color: "red",
                    }}
                  >
                    {/* <t /> */}
                    {/* &nbsp;(need VPN) */}
                  </div>
                </div>
              </Button>
            </div>
          )}
          {clientTag !== "kbz" && (
            <div className="btn-wrap">
              <Button
                className="btnChoose"
                onClick={() => {
                  setShowContactUsModal(false);
                  // let prefill_msg = "";
                  // if (productDetail) {
                  //   prefill_msg = `Hi, i'm interested in ${productDetail?.company} flight (${productDetail?.external_id}) from ${productDetail?.departure_time}to ${productDetail?.arrival_time} on ${productDetail?.departure_date} with starting price of ${productDetail?.fareClasses[0].or} ${productDetail?.fareClasses[0].currency}. Can you help me?`;
                  // }
                  // if (searchInfo) {
                  //   prefill_msg =
                  //     searchInfo?.endDate !== "0"
                  //       ? `Hi, i'm interested in ${searchInfo.fromIata} - ${searchInfo.toIata} | ${searchInfo.startDate} -> ${searchInfo.endDate}|${searchInfo.tripType}. Can you help me?`
                  //       : `Hi, i'm interested in ${searchInfo.fromIata} - ${searchInfo.toIata} |${searchInfo.startDate} |${searchInfo.tripType}. Can you help me?`;
                  // }
                  window.open(
                    `viber://pa?chatURI=flymyatravels&text=${prefill_msg}`,
                    "_blank",
                    "noopener,noreferrer",
                  );
                }}
                style={{ width: "100%" }}
              >
                <svg
                  viewBox="0 0 70 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M62.3643 6.74838C60.539 5.08995 52.6602 0.139424 36.2206 0.0677151C36.2206 0.0677151 16.7536 -1.21131 7.30362 7.34813C2.04409 12.5412 0.272229 20.2636 0.0779633 29.6874C-0.116303 39.1113 -1.12284 57.1141 16.1147 61.903C16.1147 61.903 16.0469 75.2682 16.0391 76.443C16.0391 77.2643 16.1694 77.8263 16.6388 77.9423C16.9765 78.0245 17.4824 77.8497 17.9126 77.4208C20.6689 74.6528 29.493 64.0073 29.493 64.0073C41.3328 64.7805 50.7527 62.4428 51.7658 62.1116C54.1543 61.3437 67.0737 60.2341 69.2601 42.5729C71.5196 24.3679 68.4296 11.9414 62.3643 6.74838Z"
                    fill="#7360F2"
                  />
                  <g clipPath="url(#clip0_7_130)">
                    <path
                      d="M45.6369 31.0788C45.0505 31.0788 44.5617 30.6227 44.5454 30.0362C44.3662 26.3057 42.6069 24.4811 39.0229 24.2856C38.4201 24.253 37.9477 23.7317 37.9803 23.129C38.0129 22.5262 38.5179 22.0538 39.1369 22.0864C43.8775 22.3307 46.5003 25.035 46.7447 29.9222C46.7773 30.5249 46.3048 31.0462 45.7021 31.0788C45.6695 31.0788 45.6532 31.0788 45.6369 31.0788Z"
                      fill="white"
                    />
                    <path
                      d="M51.2735 32.9685H51.2572C50.6544 32.9522 50.1657 32.4472 50.182 31.8445C50.2635 28.0813 49.1883 25.0513 46.8913 22.5425C44.6106 20.05 41.4665 18.6816 37.3124 18.3884C36.7096 18.3395 36.2535 17.8182 36.3024 17.2155C36.3512 16.6127 36.8725 16.1566 37.4753 16.2054C42.1507 16.5475 45.865 18.1929 48.5041 21.0763C51.1595 23.9761 52.4464 27.6252 52.365 31.9096C52.365 32.4961 51.8762 32.9685 51.2735 32.9685Z"
                      fill="white"
                    />
                    <path
                      d="M57.0567 35.233C56.4539 35.233 55.9652 34.7442 55.9652 34.1415C55.9163 27.5112 53.9289 22.233 50.0517 18.4536C46.1582 14.6578 41.2547 12.7192 35.4552 12.6867C34.8525 12.6867 34.3638 12.1817 34.3638 11.5789C34.3638 10.9761 34.8525 10.4874 35.4552 10.4874C41.8575 10.5363 47.266 12.6867 51.5667 16.8734C55.8837 21.0764 58.083 26.8758 58.1482 34.1089C58.1482 34.7279 57.6594 35.2167 57.0567 35.233Z"
                      fill="white"
                    />
                  </g>
                  <path
                    d="M38.2285 43.6341C38.2285 43.6341 39.7735 43.7645 40.6053 42.7397L42.2272 40.6993C43.0095 39.6875 44.8974 39.0422 46.7462 40.0722C48.1316 40.8623 49.4766 41.7213 50.7762 42.6459C52.0031 43.5481 54.5155 45.6446 54.5233 45.6446C55.7202 46.655 55.9966 48.1388 55.1817 49.7033C55.1817 49.7124 55.1752 49.7281 55.1752 49.7359C54.2777 51.2918 53.1383 52.6948 51.7997 53.8924C51.7841 53.9002 51.7841 53.9081 51.7697 53.9159C50.6067 54.8877 49.4642 55.44 48.342 55.573C48.1768 55.6019 48.0089 55.6124 47.8414 55.6043C47.3465 55.6091 46.8542 55.5333 46.3837 55.3801L46.3472 55.3266C44.6184 54.839 41.7318 53.6186 36.9247 50.9667C34.1429 49.4501 31.4945 47.7007 29.008 45.7372C27.7617 44.7535 26.5728 43.6992 25.4473 42.5794L25.3274 42.4594L25.2074 42.3395L25.0875 42.2195C25.047 42.1804 25.0079 42.14 24.9675 42.0996C23.8477 40.9741 22.7933 39.7852 21.8097 38.5389C19.8465 36.0526 18.097 33.4047 16.5802 30.6235C13.9283 25.8151 12.7079 22.9311 12.2203 21.1997L12.1668 21.1632C12.014 20.6926 11.9387 20.2003 11.9439 19.7055C11.9347 19.538 11.9448 19.3701 11.9739 19.2049C12.1138 18.0845 12.667 16.941 13.6336 15.7746C13.6414 15.7602 13.6492 15.7602 13.6571 15.7446C14.8543 14.406 16.2575 13.2669 17.8136 12.3703C17.8214 12.3703 17.837 12.3625 17.8462 12.3625C19.4107 11.5476 20.8945 11.824 21.9036 13.0144C21.9114 13.0222 24.004 15.5347 24.9023 16.7615C25.827 18.0624 26.686 19.4087 27.476 20.7955C28.506 22.643 27.8606 24.5348 26.8489 25.3145L24.8085 26.9364C23.7785 27.7682 23.914 29.3132 23.914 29.3132C23.914 29.3132 26.9363 40.7514 38.2285 43.6341Z"
                    fill="white"
                  />
                  <defs>
                    <clipPath id="clip0_7_130">
                      <rect
                        width="23.7844"
                        height="24.7455"
                        fill="white"
                        transform="translate(34.3638 10.4874)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                {t("masterHomeSecondPage.viber_chat") !==
                "masterHomeSecondPage.viber_chat"
                  ? t("masterHomeSecondPage.viber_chat")
                  : "Viber"}
              </Button>
            </div>
          )}
          <div className="btn-wrap">
            <Button
              className="btnChoose"
              onClick={() => {
                setShowContactUsModal(false);
                updateGorgiasState(true);
                if (productDetail || searchInfo) {
                  sendGorgiaMessage(productDetail, searchInfo);
                } else {
                  sendGorgiaMessage();
                }
                handleViewport();
              }}
              style={{ width: "100%" }}
            >
              <svg
                viewBox="0 0 70 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_13_12)">
                  <path
                    d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
                    fill="#2E90F7"
                  />
                  <path
                    d="M52.0333 35C52.0333 25.6667 44.5667 17.9667 35 17.9667C25.4333 17.9667 17.9667 25.6667 17.9667 35C17.9667 44.1 25.2 51.5667 34.3 51.8H45.0333V48.5333C49.2333 45.5 52.0333 40.6 52.0333 35Z"
                    fill="white"
                  />
                  <path
                    d="M30.5667 36.1667C31.211 36.1667 31.7333 35.6443 31.7333 35C31.7333 34.3557 31.211 33.8333 30.5667 33.8333C29.9223 33.8333 29.4 34.3557 29.4 35C29.4 35.6443 29.9223 36.1667 30.5667 36.1667Z"
                    fill="#008EEA"
                  />
                  <path
                    d="M35 36.1667C35.6443 36.1667 36.1666 35.6443 36.1666 35C36.1666 34.3557 35.6443 33.8333 35 33.8333C34.3556 33.8333 33.8333 34.3557 33.8333 35C33.8333 35.6443 34.3556 36.1667 35 36.1667Z"
                    fill="#008EEA"
                  />
                  <path
                    d="M39.4333 36.1667C40.0777 36.1667 40.6 35.6443 40.6 35C40.6 34.3557 40.0777 33.8333 39.4333 33.8333C38.789 33.8333 38.2667 34.3557 38.2667 35C38.2667 35.6443 38.789 36.1667 39.4333 36.1667Z"
                    fill="#008EEA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_13_12">
                    <rect width="70" height="70" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              {"Flymya Chat"}
            </Button>
          </div>
          <div className="btn-wrap">
            <Button
              className="btnChoose btnCallUs"
              onClick={() => {
                setShowContactUsModal(false);
                window.location.href = CC_PHONENO_LINK;
              }}
              style={{ width: "100%" }}
            >
              <svg
                viewBox="0 0 71 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.2 0C34.0667 0 35.7 0 37.5667 0C37.8 0 37.8 0 38.0333 0.233333C39.6667 0.466667 41.0667 0.7 42.7 0.933333C61.6 5.13333 73.5 23.8 69.3 42.7C66.0333 56.9333 54.6 67.6667 40.1333 69.7667C39.4333 70 38.5 70 37.8 70.2333C35.9333 70.2333 34.3 70.2333 32.4333 70.2333C32.2 70.2333 32.2 70.2333 31.9667 70C30.5667 69.7667 29.1667 69.5333 27.7667 69.3C13.0667 66.0333 2.56667 54.6 0.466667 39.9C0.466667 39.2 0.233333 38.5 0 37.5667C0 35.7 0 33.8333 0 32.2C0 31.9667 0 31.9667 0.233333 31.7333C0.466667 30.3333 0.7 28.9333 0.933333 27.5333C3.96667 13.0667 15.4 2.56667 29.8667 0.466667C30.8 0.233333 31.5 0.233333 32.2 0ZM25.9 32.6667C26.8333 32.2 27.7667 31.7333 28.7 31.2667C30.3333 30.5667 30.5667 29.4 29.8667 27.7667C28.4667 24.5 27.5333 21 27.5333 17.5C27.5333 16.1 26.6 15.1667 25.2 15.1667C22.6333 15.1667 20.0667 15.1667 17.7333 15.1667C16.1 15.1667 15.4 16.1 15.4 17.7333C15.4 20.7667 15.8667 23.8 16.5667 26.6C20.7667 42.9333 35.4667 54.3667 52.2667 54.6C53.9 54.6 54.8333 53.6667 54.8333 52.0333C54.8333 49.4667 54.8333 47.1333 54.8333 44.5667C54.8333 42.9333 54.1333 42 52.2667 42C48.7667 42 45.5 41.3 42.2333 39.6667C42 39.6667 42 39.4333 41.7667 39.4333C40.6 38.9667 39.4333 39.4333 38.7333 40.6C38.2667 41.5333 37.8 42.7 37.1 43.8667C32.4333 41.3 28.7 37.5667 25.9 32.6667Z"
                  fill="#2196F2"
                />
                <path
                  d="M25.9 32.6667C28.7 37.5667 32.4333 41.3 37.3333 44.1C37.8 42.9334 38.2667 42 38.9667 40.8334C39.6667 39.6667 40.6 39.2 42 39.6667C42.2333 39.6667 42.2333 39.9 42.4667 39.9C45.7333 41.3 49 42 52.5 42.2334C54.1333 42.2334 55.0667 43.1667 55.0667 44.8C55.0667 47.3667 55.0667 49.7 55.0667 52.2667C55.0667 53.9 54.1333 54.8334 52.5 54.8334C35.7 54.6 21 43.1667 16.8 26.8334C16.1 23.8 15.6333 21 15.6333 17.9667C15.6333 16.3334 16.5667 15.4 17.9667 15.4C20.5333 15.4 23.1 15.4 25.4333 15.4C26.8333 15.4 27.7667 16.3334 27.7667 17.7334C27.7667 21.2334 28.4667 24.7334 30.1 28C30.8 29.6334 30.3333 30.5667 28.9333 31.5C27.7667 31.7334 26.8333 32.2 25.9 32.6667Z"
                  fill="#F8F9F9"
                />
              </svg>
              {"Call"}
              {" : "}
              {CALL_CENTER_PHONENO}
            </Button>
          </div>
          <FlymyaTravelInfoText isInWeb={isInWeb} pageName="home" />
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

export default OfflineInternationalMsg;
